import { useState } from 'react';
import { Table, Card, Button, Modal, Form, Input, message, Space, Popconfirm } from 'antd';
function Company() {
  const [visible, setVisible] = useState(false);
  const [form] = Form.useForm();
  const [tableData, setTableData] = useState([
    {
      id: 1,
      username: '湖南博胜互动文化传媒有限公司',
      logo: 'https://sadmin.cmvstv.com//upload/system/20201124/202011241249434479.jpg',
      nickname: '博胜集团',
      intro: '博胜集团是基于IP商业价值开发及变现的一体化综合运营商，成立于2009年，集团旗下涉足的业务版块包括：互联网整合营销业务、电商运营、企业级开发应用等。成立至今公司服务主要客户有中国移动、华为、康佳、湖南卫视、湖北卫视、广西卫视、绝味、爱尔眼科等。目前与湖南卫视、云南卫视、江西卫视、湖北卫视、央视、深圳卫视……国内一线电视台已经建立战略合作关系；与熙传媒、欢瑞世纪、唐人、光线、天娱、蓝色火焰等一线制作公司也是良好的合作伙伴。合作案例：《爸爸去哪儿II》、《花儿与少年》、《如果爱II》、《勇者奇兵》、《不一样的美男者》、《古剑奇谭》、《七十二层七楼》、《明日之子》等。',
      status: 1
    },
    {
      id: 2,
      username: '长沙花匠科技发展有限公司',
      nickname: '花匠科技',
      logo: 'https://sadmin.cmvstv.com//upload/system/20230802/202308020426004289.jpg',
      intro: '长沙花匠科技发展有限公司是一家聚焦于贴身衣物的创新企业。公司创立于2015年7月，经过8年的发展，形 成了研发、设计、生产管理、市场销售与品牌运营服务的全方位创新能力;协同创新的品牌理念,使"我的花匠" 成为了中国女性内衣市场的新锐品牌。花匠公司通过用户高度参与的产品研发创新体系，让每一款新品从设计 开始到进入市场的每一个环节都有消费者参与、评估与反馈;品牌与消费者协同，将功能性、新技术和用户体 验完美匹配，从使用者的角度研发设计和生产打造了一系列拥有自主知识产权的女性内衣专利产品，涵盖护理 型内裤、提醒型内衣、One Size贴身衣物、舒适无痕内衣、微压力贴身衣物等系列产品。',
      status: 1
    },
    {
      id: 3,
      username: '湖南创意之星传媒有限公司',
      nickname: '创意之星',
      logo: 'https://sadmin.cmvstv.com//upload/system/20201124/202011240103245065.jpg',
      intro: '湖南创意之星传媒有限公司是一家具有前瞻性部署、专业化运作和规模化经营的新型文化创意传媒公司，其公司核心团队由湖南省人民广播电台、长沙市广播电视台、长沙市地铁传媒等媒体资深精英组成。',
      status: 1
    },
    {
      id: 4,
      username: '湖南钟氏沐泽珠宝贸易有限公司',
      nickname: '沐泽珠宝',
      logo: 'https://sadmin.cmvstv.com//upload/system/20230804/202308040448334652.jpg',
      intro: '湖南钟氏沐泽珠宝贸易有限公司是一家高档珠宝私人定制以及专业的珠宝首饰企业。公司成立于2015年，拥有自主独立的产品研发队伍主营业务及资源（高档珠宝私人定制、设计以及专业的珠宝首饰企业，拥有自主独立的产品研发队伍，很多产品申请了国家专利，主要从事玉、文玩、钻石、珍珠、饰品、设计等珠宝经营，同时公司旗下创建了沐泽、钟氏沐泽、沐泽圈儿、HONEY PLAN、甜蜜计划五个品牌以及国际品牌JEMUZEWEL，销售遍及国内各个省区及美国、东南亚等国家和地区），致力于从原产地采购，到后期设计、镶嵌、加工再到成品发货都会进行严格的层层筛选，只为以最专业的态度去更好的服务客户。旗下子公司沐泽文化专注于孵化服饰赛道的达人IP，为该类达人提供服饰类目一站式新电商服务，同时可为达人进行供应链定制或品牌定制。',
      status: 1
    },
    {
      id: 5,
      username: '湖南迈微互娱文化科技有限公司',
      nickname: '迈微互娱',
      logo: 'https://sadmin.cmvstv.com//upload/system/20230804/202308040355172146.jpg',
      intro: '湖南迈微互娱文化科技有限公司成立于201 6年(简称“迈微互娱”)，公司秉承 “专业、责任、创意”的理念，集聚策划、执行、摄制、后期等新媒体专业人才。致力于以短视频和直播、电商为核心内容，帮助企业提升品牌影响力和市场占有率，用内容、流量和科技赋能产业发展。',
      status: 1
    },
    {
      id: 6,
      username: '优科达',
      nickname: '优科达',
      logo: 'https://sadmin.cmvstv.com//upload/system/20230804/202308040351522207.jpg',
      intro: '优科达致力于人力资源服务产业中台建设，依托深度行业洞察及产业化运营能力，为全国1000家以上人力资源服务企业提供产品设计、市场运营、信息化建设、人才培养、运营标准化体系搭建等服务。敏捷响应多元化需求，专业开展各项服务，不断拓展产业边界，提升行业效能,以传递产业核心价值为己任，建成以用户需求为中心的一体化产业中台，助力人力资源服务产业高质量发展',
      status: 1
    },
    {
      id: 7,
      username: '微云传播',
      nickname: '微云传播',
      logo: 'https://sadmin.cmvstv.com//upload/system/20230804/202308040247489843.jpg',
      intro: '微云传播创始于2016年9月，作为成立近七年的综合性整合娱乐营销体系公司，操盘了《战至巅峰》；《半熟恋人》；《风吹半夏》；《冰雨火》等众多头部剧综整合营销。同时微云传播基于庞大的整合娱乐营销体系，逐步裂变为品牌娱乐营销及电商增长营销，围绕商业品牌年度营销需求定制整合娱乐营销服务，并不断向体育电竞垂类细分领域逐步探索。服务抖音2022年卡塔尔世界杯social传播；后街男孩全球首场视频号演唱会直播；周杰伦快手平台直播；天猫、淘宝、抖音电商、快手电商等平台重要电商大促节点传播以及飞鹤、TCL、海信等众多商业品牌植入营销。',
      status: 1
    },
    {
      id: 8,
      username: '湖南麓山行文化发展有限公司',
      nickname: '麓山行文化',
      logo: 'https://sadmin.cmvstv.com//upload/system/20230804/202308040257454934.jpg',
      intro: '湖南麓山行文化发展有限公司 (以下称“麓山行”)坐落于马栏山众创园，是一家集品牌设计、文化建设、展览展示、数字内容制作、活动策划与执行于一体的综合性文化创意公司。公司致力于成为“更懂甲方的创意团队”，将独特的设计理念与创意内容融合，精湛的施工能力与现场服务能力结合，成功为政府部门、企事业单位提供一站式品牌设计与制作服务，并与其结成了长期战略合作伙伴。麓山行一直以来秉持着“高山景行、行以致远”的经营理念，积累了丰富的行业经验。公司拥有一支高素质的服务团队，以满足客户需求为导向，不断提高设计和管理水平，持续的为客户创造价值，满足客户的多元化需求，努力实现与客户的共赢!追梦路上，让我们共同努力，让美好随处可见!',
      status: 1
    },
    {
      id: 9,
      username: '湖南众联行网络科技有限公司',
      nickname: '众联行',
      logo: 'https://sadmin.cmvstv.com//upload/system/20230804/202308040259329779.jpg',
      intro: '湖南众联行网络科技有限公司始办于2019年，是一家专业的房地产全流程综合服务商，公司通过经营房地产全产业链服务，整合资源能力，并拥有一套自己的实战策略和一系列项目运营的专业资源，目前本公司专注房地产的商业板块，集商业代理商业包销~渠道整合商业招商运营于一体的房地产机构，公司核心成员专注商业地产销售已6年之久，累积了一定的客户资源，已和长沙专做销售的分销公司建立长期的战略合作，公司一直秉承着信誉第一，赚钱第二的理念，业界内的口碑都是夸手称赞，现长期合作的分销公司已百家有余，特别是规模大专做商业的分销公司都建立了深度合作关系，同时，我们和保利.金科.电建.新城等开发商建立了长期的战略合作！',
      status: 1
    },
    {
      id: 10,
      username: '长沙集食行乐食品有限公司',
      nickname: '集食行乐',
      logo: 'https://sadmin.cmvstv.com//upload/system/20230804/202308040421091173.jpg',
      intro: '长沙集食行乐食品有限公司成立于2020年11月。我们相信，每一个小小的身体里，都蕴藏大大的能量，所以，我们聚在一起，致力于为中国儿童打造更专属的产品，让每一个孩子少点负担，多点能量，自在成长公司旗下有无限脑洞、多谷怪两大品牌，主营业务为儿童食品和儿童文创产品。儿童食品业务模块，当前主要产品为儿童麦片、儿童手抓饼、燕麦粥等。儿童文创产品业务模块，公司围绕多谷怪品牌，打造出极具有有个性化和备受儿童喜爱的品牌IP，并基于IP开发出多类儿童文创用品。未来，集食行乐将基于品牌IP不断深入开发更多儿童文创类产品，并将打造“谷怪学院”IP，创作受儿童喜爱、家长认可的成长视频。',
      status: 1
    },
    {
      id: 11,
      username: '湖南嗷嗷文化传媒有限公司',
      nickname: '嗷嗷文化',
      logo: 'https://sadmin.cmvstv.com//upload/system/20230804/202308040348171053.jpg',
      intro: '湖南嗷嗷文化传媒有限公司，总部北京百思艺腾，专注于娱乐产业的实效娱乐整合营销传播公司，集电影、电视营销策划传播、电视综艺节目策划推广、短剧短视频制作、新媒体运营等为一体。业务板块影视综艺营销策划传播、短视频制作、娱乐整合营销、新媒体运营等',
      status: 1
    },
    {
      id: 12,
      username: '京韵电商',
      nickname: '京韵电商',
      logo: 'https://sadmin.cmvstv.com//upload/system/20230804/202308040400193344.jpg',
      intro: '京韵电商是一家集京东、天猫、抖音快手、拼多多为一体的专注于健身配件类目的电商企业，公司通过牛逼的运营能力以及良好的团队氛围助力想在电商行业更上一层楼的伙伴，在一定程度上获得自我的提升，5年时间完成从0到5亿的蜕变，至今团队已有120人',
      status: 1
    },
    {
      id: 13,
      username: '湖南芒果破冰素质教育发展有限公司',
      nickname: '芒果破冰',
      logo: 'https://sadmin.cmvstv.com//upload/system/20201124/202011241249227300.jpg',
      intro: '芒果破冰前身长沙巨星文化传播有限公司，成立于2007年4月，是针对青少年，集挖掘、培养、打造、包装、输出、影视投资为一体的艺术机构。在有着将近十年的文化底蕴沉淀后，在湖南广电芒果影业的支持下，在各方资源及资金的整合下，巨星传媒做了进一步的升华，于2016年10月成立了崭新的——湖南破冰素质教育发展有限公司作为芒果影业全国唯一青少年影视产业运营部进行青少年影视相关产业的运营。',
      status: 1
    },
    {
      id: 14,
      username: '长沙荣耀文化传播有限公司',
      nickname: '荣耀文化',
      logo: 'https://sadmin.cmvstv.com//upload/system/20210312/202103120302229990.jpg',
      intro: '长沙荣耀文化传播有限公司是一家专注电视栏目、商业广告、影视制作：商业人像、活动拍摄、新媒体视觉服务为一体的平面+视频服务的潮视觉团队。',
      status: 1
    },
    {
      id: 15,
      username: '湖南定一文化传播有限公司',
      nickname: '定一传媒',
      logo: 'https://sadmin.cmvstv.com//upload/system/20201124/202011241255462004.jpg',
      intro: '定一传媒于2014年成立于长沙，是一家以前期策划拍摄为主的节目生产制作公司。现公司总人数达31人，团队核心成员曾供职于湖南卫视生产调度中心，先后制作了《变形计》第五季、第七季、第九季共三季节目。2015-2016年策划制作大型国防教育特别节目《真正男子汉》第一季、第二季。',
      status: 1
    },
    {
      id: 16,
      username: '湖南好频购网络科技有限公司',
      nickname: '好频购',
      logo: 'https://sadmin.cmvstv.com//upload/system/20201124/202011240102467200.jpg',
      intro: '湖南好频购网络科技有限公司依托了湖南佳得利百货贸易公司多年以来的电视购物销售和渠道经验以及优势的资源，同时通过长沙博学堂文化传播有限公司的系统规划和特聘复旦大学硕士生导师王雪平博士为企业的顾问为公司的发展保驾护航，未来致力于发展电商、网红效应、新媒体营销、大数据营销等当红的产业，将成为该行业当中的一支后起独秀，给国家不断的创造税收，成为园区的标榜企业。',
      status: 1
    },
    {
      id: 17,
      username: '湖南云港网络科技有限公司',
      nickname: '云港网络',
      logo: 'https://sadmin.cmvstv.com//upload/system/20201124/202011241255347624.jpg',
      intro: '云港大数据是中小企业大数据精准营销服务平台，依托智能硬件与大数据，汇集海量移动媒体资源，为企业提供移动互联网精准营销与大数据应用服务。我们的解决方案广泛应用于十余万家线下实体门店，覆盖生活服务、汽车、房产、教育、健康、金融、旅游在内的数十个垂直领域，获得市场高度认可，并与多加互联网龙头企业达成了战略合作。',
      status: 1
    },
    {
      id: 18,
      username: '湖南学纳网络科技有限公司',
      nickname: '学纳网络',
      logo: 'https://sadmin.cmvstv.com//upload/system/20201124/202011241256245482.jpg',
      intro: '启航龙图教育科技集团由成立于1998年的北京市海淀区启航考试培训学校壮大发展而来，是一家集考试培训、教育研发、图书出版、在线教育于一体的大型综合性教育科技集团。湖南学纳网络科技有限公司是启航龙图教育科技集团旗下的一份子。启航龙图教育科技集团旗下拥有北京时代云图、北京启航培训学校、北京爱启航网络科技、湖南学纳网络科技等多个子品牌。 ',
      status: 1
    },
    {
      id: 19,
      username: '长沙市大麦文化传播有限公司',
      nickname: '大麦文化',
      logo: 'https://sadmin.cmvstv.com//upload/system/20201124/202011240103079805.jpg',
      intro: '长沙市大麦文化传播有限公司成立于2011年，是一家专业从事大型活动策划、组织与执行、设计、展览展示等综合型公司。长期以来，公司秉承悉查客户需求、追求客户满意、超越客户期待、打造精品服务的经营理念和诚实守信、客户至上的服务宗旨。并一直专注服务于长沙房地产行业与金融行业，为客户提供创意以及实施多种类型的公关活动并对行业活动拥有娴熟的经验以及对流程细节抱有专业的掌控能力和应变能力。',
      status: 1
    },
    {
      id: 20,
      username: '长沙简聘信息科技有限公司',
      nickname: '简聘科技',
      logo: 'https://sadmin.cmvstv.com//upload/system/20201124/202011240100422550.jpg',
      intro: '简聘人才—长沙知名猎企，公司以猎头、RPO、小猎头专场等多种形式为客户服务，在高端制造业，IT互联网等行业具有绝对资源和经验优势，简聘是长沙市政府“3635人才引进计划”的合作伙伴；长沙经开区人才交流服务中心、高新区人才交流服务中心人才引进的官方合作机构。',
      status: 1
    },
    {
      id: 21,
      username: '湖南大煜文化文化传播有限公司',
      nickname: '大煜文化',
      logo: 'https://sadmin.cmvstv.com//upload/system/20201124/202011240322172103.jpg',
      intro: '湖南大煜文化文化传播有限公司（以下简称“公司”）核心团队作为行业最早一批专注内容孵化和社交媒体传播的专家，拥有优质的全社交媒体渠道资源，专注互联网内容运营和传播。公司于2018年4月17日在长沙成立，注册资本为200万元。目前公司业务板块主要有网红孵化运营、新媒体营销传播和视频内容策划制作。现公司已自主孵化了数十名垂直领域网红，如Beni_江酱、二毛坨子、鳕鱼叔等。此外，公司与千金药业、香奈儿、贝玲妃等诸多品牌展开了深度合作，与淘宝、微博、网易、腾讯微视、抖音等诸多机构合作，作为其入驻MCN，持续输出优质视频内容。',
      status: 1
    },
    {
      id: 22,
      username: '湖南快乐淘宝文化传媒有限公司',
      nickname: '快乐淘宝',
      logo: 'https://sadmin.cmvstv.com//upload/system/20201124/202011240100004841.jpg',
      intro: '快乐淘宝成立于2010年3月4日，注册资金10000万元。以帮助中小企业在新零售时代的转型与布局为己任，专注中小企业内容营销的业务发展方向，以图文、直播及视频为核心，充分利用直播节目及网络视频的资源方面的优势，围绕阿里的产业生态圈，借助粉丝经济、网红经济、视频内容输出等元素，打造轻型、独特的T2O模式。',
      status: 1
    },
    {
      id: 23,
      username: '湖南开门见喜文化传媒有限公司',
      nickname: '开门见喜',
      logo: 'https://sadmin.cmvstv.com//upload/system/20201124/202011240100122481.jpg',
      intro: '湖南开门见喜投资重心从广告经营逐渐发展扩大到节目内容生产、电影、大型直播活动制作、发行。与市场中独立的传媒公司最大的区别在于，大声传媒顺应传媒行业改革，与湖南广电各频道优秀制作人，直接参与大声传媒节目改版制作，公司享有节目完全著作版权。',
      status: 1
    },
    {
      id: 24,
      username: '长沙新潮文化传媒有限公司',
      nickname: '新潮文化',
      logo: 'https://sadmin.cmvstv.com//upload/system/20201124/202011241256594954.jpg',
      intro: '新潮传媒集团是 “传统媒体+互联网”的科技媒体创新企业。由创业20年的传媒老兵张继学、二三四五网络创始人庞升东和力美DSP平台创始人舒义联合创办。新潮传媒以电梯作为中产社区的流量入口，致力于用“智能硬件+软件”为客户提供更精准、更便宜的中产社区媒体流量。目前员工近7000人，已覆盖全国98个城市，近63万部电梯，日均触达近2亿人群，已成为8000多个品牌客户的选择。',
      status: 1
    },
    {
      id: 25,
      username: '湖南乐鱼传媒有限公司',
      nickname: '乐鱼传媒',
      logo: 'https://sadmin.cmvstv.com//upload/system/20201124/202011240101388597.jpg',
      intro: '乐鱼传媒目前湖南运营中心拥有专业的直播艺人及练习生造星艺人培训基地1000余平，直播基地1200余平。舞蹈室4间，声乐室2间，乐器练习室2间，直播房40余间，专业的化妆师4名，舞蹈老师6位，声乐老师及乐器老师各4位；拥有湖南本地最年轻的专业的直播艺人运营培训团队、专业艺人推广团队及短视频拍摄推广团队，通过一对一的专业培训服务带领，推动直播艺人直播生涯的发展和在粉丝经济的运营变现。在公司及直播艺人自身的努力下，公司直播艺人已经从直播间走向了线下的商演活动及主持，以及各类产品活动的拍摄宣传推广。 ',
      status: 1
    },
    {
      id: 26,
      username: '湖南达联信息科技有限公司',
      nickname: '达联信息',
      logo: 'https://sadmin.cmvstv.com//upload/system/20201124/202011240102133795.jpg',
      intro: '湖南达联信息科技有限公司是一家专注明星粉丝领域的移动互联网公司。由专业团队和众位神秘大咖共同创建，公司旗下产品：快乐粉丝会app，聚集全球各家明星粉丝，致力于给爱豆最好的支持，并为粉丝提供丰富的爱豆周边、独家签名、限量门票、星踪饭拍、星粉互动、追星基金等福利。并联合各大综艺节目组织粉丝节，公益应援，明星面对面，粉丝作品秀等活动，除此之外，快乐粉丝会已全面升级打榜应援模式，欲打造全球最有价值的明星榜单。 快乐粉丝会以公司化运作，并引入资本助力，摒弃民间的不规范。除众位大咖的支持外，特别邀请资深粉丝成为粉丝会合伙人，和会长一起守护爱豆、服务粉丝，把追星和事业融为一体，让追星更有意义。 快乐粉丝会终极愿景是：把最好的应援给最好的爱豆，并引导粉丝正向追星。',
      status: 1
    },
    {
      id: 27,
      username: '湖南松雅传媒有限公司',
      nickname: '松雅传媒',
      logo: 'https://sadmin.cmvstv.com//upload/system/20201124/202011241259066979.jpg',
      intro: '湖南松雅传媒有限公司是一家综合型媒体类广告创意及户外资源运营公司，公司自2010年成立以来，坚持走区域综合媒体运营商的战略发展方向，秉承诚信服务和客户至上的服务思维，凭借强有力的创意团队、专业的广告运营思路和规模化广告媒体资源运营，公司于2013年被中国广告协会评为湖南省二级广告企业、国家三级广告企业。 凭借多年实践经验的积累，为众多企业品牌扩大市场影响力、提升品牌知名度、拓宽销售渠道提供了专业的服务。未来，公司将重点开发视频创意领域、品牌展示创意及形象包装、户外媒体投放三大核心业务，衷心期待与您携手共谋品牌发展之路！',
      status: 1
    },
    {
      id: 28,
      username: '长沙有啦云传媒有限责任公司',
      nickname: '有啦云传媒',
      logo: 'https://sadmin.cmvstv.com//upload/system/20201124/202011241259295585.jpg',
      intro: '中国第一家以视频为特色的，专注母婴营养健康知识传播和咨询的创新型社区媒体平台。 有啦云传媒为更好服务当代中国母婴人群营养健康需求而创立，是中国母婴人群，包括宝宝父母、母婴医生、护士等人群自己的，以视频为特色的社区媒体。 平台致力于提高全民母婴营养健康知识水平，在母婴领域，助力国家推进“家庭医生计划”以及“国民营养计划”，进一步推广“健康医学”理念，提高母婴人群的生存质量。',
      status: 1
    },
    {
      id: 29,
      username: '长沙乐橘数字科技有限公司',
      nickname: '乐橘科技',
      logo: 'https://sadmin.cmvstv.com//upload/system/20201124/202011240100547499.jpg',
      intro: '长沙乐橘数字科技有限公司成立于2019年1月，位于长沙马栏山众创园，是一家从事视频数字产业互联网信息平台建设、移动App和web应用软件开发的高新技术企业，致力于视频产业内容生产制作和产业IT技术开发服务。',
      status: 1
    },
    {
      id: 30,
      username: '湖南智创视通企业管理运营有限公司',
      nickname: '智创视通',
      logo: 'https://sadmin.cmvstv.com//upload/system/20201124/202011240144224316.jpg',
      intro: '“草莓V视“创立于2017年，是湖南智创视通企业管理运营有限公司旗下的视频文创产业基地品牌，我们通过投资视频文创孵化器与全方位资源整合、利用智慧云公共服务系统实现信息化管理和数据化运营，双向赋能IP内容与商业市场，构建线上线下一体化企业服务平台，为入驻企业提供办公空间、商业社区及增值服务。',
      status: 1
    },
    {
      id: 31,
      username: '长沙易及传媒有限公司',
      nickname: '易及传媒',
      logo: 'https://sadmin.cmvstv.com//upload/system/20201124/202011240102347573.jpg',
      intro: '在上海和长沙开设办公室。目前公司在抖音快手微视今日头条微信等平台拥有近3000万粉丝，系长撒谎头部短视频内容创业者粉丝最多的新媒体公司之一。',
      status: 1
    },
    {
      id: 32,
      username: '湖南慢点娱乐文化传媒有限公司',
      nickname: '慢点娱乐',
      logo: 'https://sadmin.cmvstv.com//upload/system/20201124/202011240103382771.jpg',
      intro: '湖南慢点娱乐文化传媒有限公司成立于2016年1月，现核心团队共计20人左右，公司主营业务板块：综艺节目制作、大型活动执行、视觉设计包装等。公司以“慢点”命名，旨在提倡慢工匠人精神，充分发挥电视湘军的优良基因，对标市场，积极原创，细心打磨，在文化视频领域打造自己的版图。',
      status: 1
    },
    {
      id: 33,
      username: '湖南奈斯文化传播有限公司',
      nickname: '奈斯文化',
      logo: 'https://sadmin.cmvstv.com//upload/system/20201124/202011240104426663.jpg',
      intro: '湖南奈斯文化传媒有限公司立足于长沙，云集电视湘军精英人才，构建成以提供优质视频内容为核心竞争力的后期剪辑、影视动画特效、频道改版包装、节目整体包装设计制作等为核心的实力强大的制作团队。',
      status: 1
    },
    {
      id: 34,
      username: '湖南哔哩吧啦传媒有限公司',
      nickname: '哔哩吧啦',
      logo: 'https://sadmin.cmvstv.com//upload/system/20201124/202011240106529863.jpg',
      intro: '湖南哔哩吧啦传媒有限公司创立于2019年，以影视剧拍摄、活动策划，商业演绎等广告业及其相关行业为服务内容。在电视节目制作领域我们更加专注于“市场与服务”，为客户量身定做策划方案，不断追求创新和完美，以助更多合作伙伴腾飞。我们拥有一批专业的、年轻的、高素质的工作团队，完善的运作管理流程，严谨的工作作风和良好的客户信誉。自创办以来，时刻关注市场动态，强调专业性与服务性的完美结合，以自己的诚挚和信誉赢得了众多客户的认可与信赖。',
      status: 1
    },
    {
      id: 35,
      username: '湖南省南子影视传媒有限公司',
      nickname: '南子影视',
      logo: 'https://sadmin.cmvstv.com//upload/system/20201124/202011240105142762.jpg',
      intro: '南子影业节目制作团队是湖南广电制播分离体制的先行军，拥有业 内资深的一线导演团队、摄像团队和后期制作团队。拥有丰富的节目 制作策划、制作、企业宣传片、广告片的执行经验。团队致力于为户 外真人秀和棚内综艺节目提供优质的内容，成为国内领先的原创视频 内容制作公司。',
      status: 1
    },
    {
      id: 36,
      username: '湖南红频道文化产业有限公司',
      nickname: '红频道',
      logo: 'https://sadmin.cmvstv.com//upload/system/20201124/202011240106315221.jpg',
      intro: '主要从事小程序与学习型游戏运营，电影纪录片策划与制作，百家姓物质与文化传承，姓氏名胜古迹与宗祠墓园修缮与开发，新媒体创意运营，基于姓氏文化和广告文化的视频直播等业务。',
      status: 1
    },
    {
      id: 37,
      username: '湖南新言传媒有限公司',
      nickname: '新言传媒',
      logo: 'https://sadmin.cmvstv.com//upload/system/20201124/202011240106152007.jpg',
      intro: '新言传媒由湖南原广电系金牌商务策划人，资深运营总监，内容电商领头人，以及多年TP（淘拍档）负责人共同创建。通过“人”与内容，赋能传统供应链和渠道，共建品牌传播，商品营销的新模式，新业态',
      status: 1
    },
    {
      id: 38,
      username: '湖南千帆传媒有限公司',
      nickname: '千帆传媒',
      logo: 'https://sadmin.cmvstv.com//upload/system/20201124/202011240232192834.jpg',
      intro: '湖南千帆传媒有限公司是一家专业从事互联网资源整合、资源输出、软件开发及网络推广的公司。公司主要以中小型企业及实体门店的活动策化，拓客引流，成交转化为服务宗旨。用网络工具，自媒体及社交平台通过技术来实现商家的业绩倍增；同时还搭建了直播培训体系及直播设备工厂生产进出口业务。',
      status: 1
    },
    {
      id: 39,
      username: '湖南小逆供应链管理有限公司',
      nickname: '小逆供应链',
      logo: 'https://sadmin.cmvstv.com//upload/system/20201124/202011241251073733.jpg',
      intro: '小逆播商--专注直播电商。公司位于马栏山直播产业基地一楼，拥有近1000平米的选品中心以及14个直播间，是省内第一家场景化直播带货培训和实战基地。通过链接产品、渠道和流量，打通人、货、场，营造省内直播电商的优质生态环境，与直播电商产业链相融共生，共同成长。',
      status: 1
    },
    {
      id: 40,
      username: '湖南爱豆有好货新媒体有限公司',
      nickname: '爱豆有好货',
      logo: 'https://sadmin.cmvstv.com//upload/system/20201124/202011240305477478.jpg',
      intro: '湖南爱豆有好货新媒体有限公司是一家专注于互联网+的新媒体营销公司，通过短视频制作策划、直播策划执行 、社交电商运营等营销方式，为品牌广告主提供智能化的营销解决方案，同时帮助明星艺人、网红达人实现价值商业化。',
      status: 1
    },
    {
      id: 41,
      username: '湖南大瓜文化传媒有限公司',
      nickname: '大瓜文化',
      logo: 'https://sadmin.cmvstv.com//upload/system/20201123/202011230539566776.jpg',
      intro: '湖南大瓜文化传媒有限公司是由湖南卫视及快乐购精英班底组建的独立MCN及直播公司，其管理团队与骨干员工均为湖南广电集团核心部门管理层、核心研发团队及节目制作团队。大瓜拥有专业、高效的精英团队，具备平面拍摄、视频制作、自媒体运营、红人包装、推广策划，数据分析等业务能力；致力于向网民提供高质、高创意的短视频内容，链接中国网民与全球优秀品牌，为优秀品牌或个人提供高品质内容服务，打造独一无二的中国市场的视频营销全套解决方案。',
      status: 1
    },
    {
      id: 42,
      username: '长沙爱丽丝工房文化传播有限公司',
      nickname: '爱丽丝工房',
      logo: 'https://sadmin.cmvstv.com//upload/system/20201124/202011240145423262.jpg',
      intro: '本公司成立于2015年，前身为2006年成立的个人工作室Alice工坊，成立的初衷便是专门为日式动画与日式游戏提供声音制作服务以及解决方案。公司目前常驻人数为8人。音乐3人，音效3人，混音1人，全项目1人。公司理念：希望把作品的听感做到“理所当然”四个字，近10年来从录音工艺，音乐制作理念，音效设计理念，混音工艺流程，听觉设计框架，全部朝着日式动画的听感靠拢，目前本公司负责制作的一些日语版动画在听觉和技术标准上已经和日本产的动画基本持平，可以交付于以标准严格著称的日本电视台播放。',
      status: 1
    },
    {
      id: 43,
      username: '长沙抓马娱乐传媒有限公司',
      nickname: '抓马娱乐',
      logo: 'https://sadmin.cmvstv.com//upload/system/20201124/202011240203052806.jpg',
      intro: '长沙抓马娱乐传媒有限公司位于马栏山视频文化产业园，是由资深媒体人创建的短视频娱乐团队，主要定位为娱乐短视频资讯，目前在今日头条、腾讯新闻、快手等各大平台已打造出“抓马星闻”、“抓马视频”等优质账号，抓马娱乐的宗旨是关注贵圈，专注娱乐！',
      status: 1
    },
    {
      id: 44,
      username: '湖南百纳千寻新媒体有限责任公司',
      nickname: '百纳千寻',
      logo: 'https://sadmin.cmvstv.com//upload/system/20201124/202011240203342025.jpg',
      intro: '北京华录百纳影视股份有限公司（股票代码：300291 ）成立于 2002 年，始终以“ “ 时代影像，影响时代” ”为企业使命，以弘扬主流价值观为精品内容创作理念。2012 年以首个央企背景的影视传媒集团身份成功登陆A A 股市场。 2018 年响应央企混改，并入盈峰投资控股集团旗下。百纳千寻是华录百纳适应新内容、新营销的市场环境而创办的直播子公司，致力于打造以直播、短视频、供应链三大版块为链条的 IP 运营商业生态。',
      status: 1
    },
    {
      id: 45,
      username: '乐温科技教育(深圳)有限公司长沙分公司',
      nickname: '乐温科技',
      logo: 'https://sadmin.cmvstv.com//upload/system/20201124/202011240213395184.jpg',
      intro: '机甲大师长沙俱乐部由大疆官方授权，成立于2020年，是一家以机器人教育培训为核心，立足于机器人高端教育市场拓展，并开展相应的课程培训和服务，集产品代理、品牌运营、赛事运维、提供教育应用解决方案的高科技产业公司。',
      status: 1
    },
    {
      id: 46,
      username: '北京信工博特智能科技有限公司',
      nickname: '信工博特',
      logo: 'https://sadmin.cmvstv.com//upload/system/20201124/202011240302121075.jpg',
      intro: '北京信工博特智能科技有限公司 ，为内容运营者、内容运营平台、内容监督方提供信息内容安全评估、信息内容安全审核、信息内容安全完整解决方案。公司帮助各类型企业优化审核机制，共同营造全球化网络信息内容智能审核生态。 推出了文小宝、AI智能图鉴 等系列产品，覆盖直播、金融、支付、社交、电商、游戏、O2O等行业 。',
      status: 1
    },
    {
      id: 47,
      username: '长沙硬核文化传媒有限公司',
      nickname: '硬核文化',
      logo: 'https://sadmin.cmvstv.com//upload/system/20201124/202011240320446485.jpg',
      intro: '长沙硬核文化传媒有限公司成立于2019年04月26日，注册地位于长沙市雨花区圭塘路206号香丽名苑第3栋、4栋、5栋19层1923号，法定代表人为冯滔。经营范围包括文化活动的组织与策划；品牌策划咨询服务；影视策划；文化艺术咨询服务；群众参与的文艺类演出、比赛等公益性文化活动的策划；企业管理咨询服务；音乐及视频产品互联网销售；电子产品互联网销售；农产品、文化艺术品、食品的互联网销售；商业信息咨询；美术展览经纪代理服务；散装食品、禽、蛋及水产品、农产品、果品及蔬菜销售；家用电器及电子产品、图书、报刊零售；音像经纪代理服务；演出经纪；动漫经纪代理服务；文学、艺（美）术经纪代理服务；以数字印刷方式从事出版、包装装潢印刷品和其他印刷品印刷；文艺创作服务；影视节目发行；艺术、美术创作服务；文化娱乐经纪；影视经纪代理服务；版权服务；广播电视节目制作；录音制作；数字内容服务；动漫及衍生产品设计服务；书法作品创作；图书选题策划服务；互联网信息服务、信息技术咨询、广告服务；知识产权代理；信息网络传播视听节目业务；著作权代理；市场管理服务；信息技术咨询服务；广告制作服务；广告发布服务；广告国内代理服务；广告设计；广告国内外代理服务；图书批发；图书互联网销售；书、报刊印刷。',
      status: 1
    },
    {
      id: 48,
      username: '湖南奕叶文化发展有限公司',
      nickname: '奕叶文化',
      logo: 'https://sadmin.cmvstv.com//upload/system/20201124/202011240320049875.jpg',
      intro: '湖南奕叶文化发展有限公司成立于2019年06月13日，注册地位于长沙市开福区月湖街道鸭子铺路1号260房2室，法定代表人为彭平英。经营范围包括文艺表演、体育、娱乐活动的策划和组织；大型活动组织策划服务；文化艺术讲座活动的组织策划；文化设计与建设；美术图案、展台、动漫及衍生产品、包装装潢的设计服务；广告制作服务；导向标识设计；导向标识制作（限分支机构）；企业形象策划服务；品牌策划咨询服务；会议、展览及相关服务；培训活动的组织；教育咨询（不含托管、培训）；建筑工程后期装饰、装修和清理。（依法须经批准的项目，经相关部门批准后方可开展经营活动）',
      status: 1
    },
    {
      id: 49,
      username: '湖南大白互娱网络科技有限公司',
      nickname: '大白互娱',
      logo: 'https://sadmin.cmvstv.com//upload/system/20201124/202011240345087162.jpg',
      intro: '湖南大白互娱网络科技有限公司，由原湖南广电节目制作团队、琴岛直播运营团队共同组建，依托于湖南广电，打通湖南广电相关媒体推广资源，致力于品牌策划、品牌推广、节目制作、短视频孵化、网红及达人包装等。',
      status: 1
    },
    {
      id: 50,
      username: '湖南梦莉源文化传媒有限公司',
      nickname: '梦莉源文化',
      logo: 'https://sadmin.cmvstv.com//upload/system/20201124/202011240345394880.jpg',
      intro: '湖南梦莉源文化传媒有限公司成立于2019年03月05日，注册地位于长沙市开福区洪山街道福元东路福鑫苑B4栋1楼106室04号，法定代表人为张宇生。经营范围包括文化活动的组织与策划；体育活动的组织与策划；商业活动的组织；学术交流活动的组织；文化艺术咨询服务；体育运动咨询服务；策划创意服务；文化用品设备出租；日用品出租；文化艺术竞赛活动的组织策划；文化艺术讲座活动的组织策划；文化艺术交流活动的组织；文化产品研发；群众文体活动；文化娱乐经纪；体育经纪；市场营销策划服务；互联网信息技术咨询；商品信息咨询服务；移动互联网研发和维护；音乐及视频产品互联网销售。（依法须经批准的项目，经相关部门批准后方可开展经营活动）',
      status: 1
    },
    {
      id: 51,
      username: '湖南芒果声音工场传媒有限公司',
      nickname: '芒果声音工场',
      logo: 'https://sadmin.cmvstv.com//upload/system/20201124/202011240345537832.jpg',
      intro: '2017年7月，1069年代音乐台倾力孵化的芒果儿童横空破茧，温暖启航。芒果儿童以儿童电台、儿童剧场、少儿活动、少儿教育基地等项目为主体，致力于打造全国示范品牌的儿童教育生态圈。',
      status: 1
    },
    {
      id: 52,
      username: '湖南益乘科技有限公司',
      nickname: '益乘科技',
      logo: 'https://sadmin.cmvstv.com//upload/system/20201124/202011240444388275.jpg',
      intro: '湖南益乘科技有限公司（以下简称益乘）是集研发、制造、销售于一体的科技型互联网＋汽车用品公司，成立于2012年，注册资本为伍佰万，年平均营业收入达五千万，为湖南省高新技术企业、湖南省电子商务示范企业。',
      status: 1
    },
    {
      id: 53,
      username: '长沙海乐文化传播有限公司',
      nickname: '海乐文化',
      logo: 'https://sadmin.cmvstv.com//upload/system/20201124/202011240344397811.jpg',
      intro: '长沙海乐文化传播有限公司是一家以家文化、企业梦、以人为本作为 管理理念的创新型互联网公司。',
      status: 1
    },
    {
      id: 3,
      username: '湖南聚壹文化传媒有限公司',
      nickname: '聚壹文化',
      logo: 'https://sadmin.cmvstv.com//upload/system/20201124/202011240401277656.jpg',
      intro: '湖南聚壹文化传媒有限公司成立于2019年10月28日，注册地位于长沙市开福区月湖街道鸭子铺路1号92房1室，法定代表人为熊天一。经营范围包括文化活动的组织与策划；文艺表演、体育、娱乐活动的策划和组织；文化创意设计；广播电视节目制作；舞台表演美工服务、道具服务、化妆服务；舞台灯光、音响设备安装服务；电子显示屏及舞台设备的设计与安装；广告设计；商业活动的策划。（依法须经批准的项目，经相关部门批准后方可开展经营活动）',
      status: 1
    },
    {
      id: 3,
      username: '湖南归蓝间文化传媒有限公司',
      nickname: '归蓝间文化',
      logo: 'https://sadmin.cmvstv.com//upload/system/20201124/202011240403448274.jpg',
      intro: '湖南归蓝间文化传媒有限公司成立于2019年09月16日，注册地位于长沙市开福区月湖街道鸭子铺路1号47房1室，法定代表人为杨博轩。经营范围包括文化活动的组织与策划；文化设计与建设；文化创意、招牌、影视烟火特效、文创产品、城市形象、公园规划、文化遗产展示、机场地面配套设备、导向标识、旅游规划设计；虚拟现实制作；广告设计；广告制作服务；声学设计咨询；动漫及衍生产品设计服务；游戏软件设计制作；旅游景区规划设计、开发、管理；数字动漫制作；增强现实制作；课件拍摄制作；影视策划；广播电视节目制作；摄影服务。（依法须经批准的项目，经相关部门批准后方可开展经营活动）',
      status: 1
    },
    {
      id: 3,
      username: '长沙奥润吉文化传播有限公司',
      nickname: '奥润吉文化',
      logo: 'https://sadmin.cmvstv.com//upload/system/20201124/202011240400388057.jpg',
      intro: '长沙奥润吉文化传播有限公司成立于2019年12月26日，注册地位于长沙市雨花区湘府东路二段258号双塔国际广场B座1609房卡011座，法定代表人为吴贵彬。经营范围包括文化艺术交流活动的组织；文化活动服务；互联网信息技术咨询；信息技术咨询服务；品牌策划咨询服务；企业管理咨询服务；文化艺术咨询服务；经济与商务咨询服务；商业信息咨询；计算机技术咨询；智能技术咨询、服务；市场营销策划服务；会议、展览及相关服务；电子商务平台的开发建设；广告设计；商业活动的组织；商业活动的策划；文艺表演、体育、娱乐活动的策划和组织；图文制作；计算机网络平台的开发及建设；通信工程设计服务；广告制作服务；广告发布服务；广告国内代理服务；工艺品、化妆品、日用百货、鞋帽、文具用品、消防设备及器材、果品、蔬菜、干果、坚果、粮油、调味品、生鲜家禽、家用电器及电子产品、眼镜、玩具、酒、饮料及茶叶、预包装食品的零售；电子产品、计算机软件、计算机辅助设备、电子产品及配件、五金机电产品、炒货食品及坚果制品、服装配饰、日用品的销售；农副产品销售。',
      status: 1
    },
    {
      id: 3,
      username: '长沙市晒米文化传播有限公司',
      nickname: '晒米文化',
      logo: 'https://sadmin.cmvstv.com//upload/system/20201124/202011240357084244.jpg',
      intro: '长沙市晒米文化传播有限公司成立于2018年05月07日，注册地位于长沙市开福区月湖街道鸭子铺路1号44房7室，法定代表人为黄越君。经营范围包括文化活动的组织与策划；影视策划；大型活动组织策划服务；舞台灯光、音响设备安装服务；自媒体新闻发布服务；广告制作服务、发布服务、国内代理服务；广告设计；广播电视节目制作；录音制作；课件拍摄制作；灯光设备租赁；音频和视频设备租赁；电子显示屏及舞台设备的设计与安装；广播电视卫星设备批发',
      status: 1
    },
  ]);
  const [selectItem, setSelectItem] = useState({});

  const columns = [
    {
      title: '序号',
      dataIndex: 'index',
      key: 'index',
      width: 60,
      align: 'center',
      render: (text, record, index) => index + 1
    },
    {
      title: '企业名称',
      dataIndex: 'username',
      key: 'username',
      render: (text, record) => {
        return (
          <Space>
            <div style={{ width: '64px', height: '64px' }}>
              <img src={record.logo} alt={text} width={'100%'} height={'100%'} style={{ objectFit: 'contain' }} />
            </div>
            <span>{text}</span>
          </Space>
        )
      }
    },
    {
      title: '企业简称',
      dataIndex: 'nickname',
      key: 'nickname'
    },
    {
      title: '企业简介',
      dataIndex: 'intro',
      key: 'intro',
      width: 800
    },
    {
      title: '状态',
      dataIndex: 'status',
      key: 'status',
      render: (text, record) => {
        return record.status === 1 ? '正常' : '禁用'
      }
    },
    {
      title: '操作',
      dataIndex: 'operation',
      key: 'operation',
      render: (text, record, index) => {
        return (
          <Space>
            <Button type='primary' size='small' onClick={() => editClick(record)}>编辑</Button>
            <Popconfirm
              size='small'
              title="确定要删除吗？"
              onConfirm={() => delClick(index)}
              onCancel={() => setVisible(false)}
              okText="确定"
              cancelText="取消"
            >
              <Button type='text' danger size='small'>删除</Button>
            </Popconfirm>
          </Space>
        )
      }
    }
  ]
  // 添加活动事件
  const addClick = () => {
    setVisible(true);
    form.resetFields();
  }
  // 确认添加事件
  const handleOk = () => {
    form.validateFields().then(values => {
      let data = [...tableData];
      if (selectItem.id) {
        data = data.map(item => {
          if (item.id === selectItem.id) {
            return { ...item, ...values }
          }
          return item;
        })
      } else {
        data.unshift({ id: tableData.length + 1, ...values, status: 1, });
      }
      setTableData(data);
      message.open({
        type: 'success',
        content: '操作成功'
      })
      setVisible(false);
      form.resetFields();
    }).catch(err => {
      message.open({
        type: 'error',
        content: '请检查表单填写是否正确'
      })
    });
  }
  // 取消添加事件
  const handleCancel = () => {
    setVisible(false);
    form.resetFields();
  }
  // 删除事件
  const delClick = (index) => {
    let data = [...tableData];
    data.splice(index, 1);
    setTableData(data);
    message.open({
      type: 'success',
      content: '删除成功'
    })
  }
  // 编辑事件
  const editClick = (record) => {
    setVisible(true);
    form.setFieldsValue(record);
    setSelectItem(record);
  }

  return (
    <>
      <div>
        <Card
          title="用户管理"
          extra={
            <Button type='primary' className='addBtn' onClick={addClick}>添加企业</Button>
          }
        >
          <Table
            className='news-table'
            rowKey={(record) => record.id}
            columns={columns}
            dataSource={tableData}
          />
        </Card>

        <Modal
          title="添加企业"
          open={visible}
          destroyOnClose={true}
          onCancel={handleCancel}
          footer={
            [
              <Button key="back" onClick={handleCancel} className='modalFooterBtn'>取消</Button>,
              <Button key="submit" type="primary" onClick={handleOk} className='modalFooterBtn'>确认</Button>
            ]
          }
        >
          <Form
            form={form}
            labelCol={{ span: 4 }}
            wrapperCol={{ span: 18 }}
            preserve={false}
          >
            <Form.Item
              label="企业名称"
              name="username"
              rules={[{ required: true, message: '请输入企业名称' }]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="企业简称"
              name="nickname"
              rules={[{ required: true, message: '请输入企业简称' }]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="企业简介"
              name="intro"
              rules={[
                { required: true, message: '请输入企业简介' }
              ]}
            >
              <Input.TextArea maxLength={11} />
            </Form.Item>
            <Form.Item
              label="备注"
              name="remark"
            >
              <Input />
            </Form.Item>
          </Form>
        </Modal>
      </div>
    </>
  )
};

export default Company;