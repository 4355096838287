import { Link, useNavigate } from 'react-router-dom';
import { Avatar, Dropdown, Space } from 'antd';

import { removeStorage } from '@/utils';
import './header.scss';

function HeaderComponent() {
  const navigate = useNavigate();

  const avatarImg = require('@/assets/avatar.png');

  const logoutClick = () => {
    removeStorage();
    navigate('/login', { replace: true });
  }

  const items = [
    {
      key: '1',
      label: <Link to={'/'}>统计面板</Link>
    },
    {
      type: 'divider'
    },
    {
      key: '2',
      label: <Link to={'/profile'}>个人中心</Link>
    },
    {
      key: '3',
      danger: true,
      label: <div onClick={logoutClick}>退出登录</div>
    }
  ];


  return (
    <>
      <div className='header-wrap'>
        <Dropdown
          size='large'
          menu={{
            items,
          }}
        >
          <a onClick={(e) => e.preventDefault()}>
            <Space>
              <div>你好，管理员</div>
              <Avatar size={32} src={avatarImg} />
            </Space>
          </a>
        </Dropdown>
      </div>
    </>
  )
};

export default HeaderComponent;