import { useState } from "react";
import { Card, Row, Col, Descriptions,Form, Input, Button, message } from "antd";
import './profile.scss';

function Profile() {
  const [active, setActive] = useState(0);
  const items = [
    {
      key: '1',
      label: '账号',
      children: <p>admin</p>,
    },
    {
      key: '2',
      label: '手机号码',
      children: <p>18152124488</p>,
    },
    {
      key: '3',
      label: '状态',
      children: <p>正常</p>,
    },
    {
      key: '4',
      label: '备注',
      children: <p>empty</p>,
    },
    {
      key: '5',
      label: '地址',
      children: <p>--</p>,
    },
  ];
  const [form] = Form.useForm();

  const confirmForm = () => {
    form.validateFields().then(values => {
      // console.log('values', values);
      message.open({
        type: 'success',
        content: '修改成功'
      })
    }
    ).catch(error => {
      console.log('error', error);
    });
  }

  return (
    <Card title="个人信息" className="profile-wrap">
      <Row gutter={[40, 40]}>
        <Col span={4}>
          <ul className="menu-ul">
            <li className="active" onClick={() => setActive(0)}>基本信息</li>
            <li onClick={() => setActive(1)}>修改密码</li>
          </ul>
        </Col>
        <Col span={20}>
          {
            active === 0 && (
              <Descriptions title="管理员" items={items} />
            )
          }

          {
            active === 1 && (
              <Form
                form={form}
                labelCol={{ span: 4 }}
                wrapperCol={{ span: 14 }}

              >
                <Form.Item
                  name="oldPassword"
                  label="旧密码"
                  rules={[{ required: true, message: '请输入旧密码' }]}
                >
                  <Input.Password />
                </Form.Item>
                <Form.Item
                  name="newPassword"
                  label="新密码"
                  rules={[{ required: true, message: '请输入新密码' }]}
                >
                  <Input.Password />
                </Form.Item>
                <Form.Item
                  name="confirmPassword"
                  label="确认密码"
                  rules={[{ required: true, message: '请确认密码' }]}
                >
                  <Input.Password />
                </Form.Item>
                <Form.Item wrapperCol={{ offset: 4 }}>
                  <Button type="primary" onClick={confirmForm}>提交</Button>
                </Form.Item>
              </Form>
            )
          }
        </Col>
      </Row>
    </Card>
  );
};

export default Profile;