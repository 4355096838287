import { Card, Row, Col } from "antd";

import './material.scss';

function Material() {
  return (
    <>
      <Card title="素材库">
        <Row gutter={[20, 20]}>

          {
            Array(128).fill(0).map((item, index) => {
              return (
                <Col span={3} key={index}>
                  <div className="img-div">
                    <img src={require(`./images/${index+1}.jpg`)} className="img" />
                  </div>
                </Col >
              )
            })
          }
          
        </Row>
      </Card>
    </>
  );
};

export default Material;