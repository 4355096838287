const key = 'ty-platform-backstage-token';

export function getStorage() {
  return localStorage.getItem(key);
}

export function setStorage(value) {
  return localStorage.setItem(key, value);
}

export function removeStorage() {
  return localStorage.removeItem(key);
}
