import { useNavigate } from 'react-router-dom';
import {Row, Col, Form, Input, Button, Card} from 'antd';
import { setStorage } from '@/utils';
import './login.scss';

function Login() {
  const navigate = useNavigate();
  const [form] = Form.useForm();

  const loginClick = () => {
    form.validateFields().then(values => {
      setStorage('skj0033KJDHAOlsi-23');
      navigate('/', { replace: true });
    }).catch(err => {
      console.log(err);
    });
  }

  return (
    <>
      <div className="login-wrap">
        <Row gutter={0}>
          <Col span={12}>
            <div className='left-div'>
              <div className='topic'>天衍信息服务平台管理后台</div>
              <div className='login-intro'>以直播基地为载体，直播培训为核心内容，</div>
              <div className='login-intro'>对接商家需求，将直播孵化基地作为共享平台。</div>
            </div>
          </Col>
          <Col span={12}>
            <div className='right-div'>
              <Card>
                <div className='login-box-title'>欢迎登录天衍信息服务平台后台管理系统</div>
                <Form
                  form={form}
                  name="login"
                  className="login-form"
                >
                  <Form.Item
                    name="username"
                    rules={[{ required: true, message: '请输入用户名' }]}
                    style={{ marginBottom: 30 }}
                  >
                    <Input
                      placeholder="用户名"
                      size="large"
                    />
                  </Form.Item>
                  <Form.Item
                    name="password"
                    rules={[{ required: true, message: '请输入密码' }]}
                    style={{ marginBottom: 30 }}
                  >
                    <Input.Password
                      placeholder="密码"
                      size="large"
                    />
                  </Form.Item>
                  <Form.Item>
                    <Button type="primary" block size="large" onClick={loginClick}>
                      登录
                    </Button>
                  </Form.Item>
                </Form>
              </Card>
            </div>
          </Col>
        </Row>
      </div>
    </>
  )
};

export default Login;