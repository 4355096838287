import { useState, useEffect } from 'react';
import { Row, Col, Card, Statistic, Radio, Table } from 'antd';
import { ArrowUpOutlined, ArrowDownOutlined } from '@ant-design/icons';
import LineCharts from '@/components/charts/line-charts';
import LineChart2 from '@/components/charts/line-chart2';
import './home.scss';
function Home() {


  // 站点访问量数据
  const visitData = [13, 22, 24, 28, 31, 26, 22, 22, 25, 30, 17, 22, 38, 36, 30, 26, 24, 16, 25, 31, 25, 14, 32, 10, 24, 20, 25, 30, 34, 30];
  // 活动数量数据
  const activityData = [0, 0, 0, 0, 2, 1, 0, 0, 0, 0, 0, 0, 0, 1, 2, 1, 1, 1, 0, 0, 0, 0, 1, 2, 1, 1, 2, 2, 1, 0];
  // 作品数量数据
  const worksData = [5, 4, 4, 5, 4, 5, 5, 2, 3, 2, 1, 5, 6, 7, 4, 5, 1, 6, 7, 1, 2, 3, 14, 2, 5, 4, 6, 8, 5, 2];

  // 热点趋势日期选择
  const [hotTrendsDate, setHotTrendsDate] = useState('week');
  // 热点趋势x轴展示数据
  const [xdata, setXdata] = useState([]);
  // 热点趋势y轴展示数据
  const [ydata, setYdata] = useState([]);
  // 热点趋势X轴数据-近7天
  const [hotTrendsXData7Days, setHotTrendsXData7Days] = useState([]);
  // 热点趋势Y轴数据-近7天
  const [hotTrendsYData7Days, setHotTrendsYData7Days] = useState([]);
  // 热点趋势X轴数据-近30天
  const [hotTrendsXData30Days, setHotTrendsXData30Days] = useState([]);
  // 热点趋势Y轴数据-近30天
  const [hotTrendsYData30Days, setHotTrendsYData30Days] = useState([]);
  // 热点趋势X轴数据-近12个月
  const [hotTrendsXData12Months, setHotTrendsXData12Months] = useState([]);
  // 热点趋势Y轴数据-近12个月
  const [hotTrendsYData12Months, setHotTrendsYData12Months] = useState([]);

  // 表格头
  const columns = [
    {
      title: '关键词',
      dataIndex: 'keyword',
      key: 'keyword',
    },
    {
      title: '点击量',
      dataIndex: 'clicks',
      key: 'clicks',
    },
    {
      title: '展现量',
      dataIndex: 'impressions',
      key: 'impressions',
    },
    {
      title: '点击率',
      dataIndex: 'ctr',
      key: 'ctr',
    }
  ]
  // 表格数据
  const dataSources = [
    {
      key: '1',
      keyword: '直播产业',
      clicks: 4,
      impressions: 61,
      ctr: '6.5%'
    },
    {
      key: '2',
      keyword: '马栏山',
      clicks: 10,
      impressions: 118,
      ctr: '8.5%'
    },
    {
      key: '3',
      keyword: '信息服务平台',
      clicks: 24,
      impressions: 60,
      ctr: '40%'
    },
    {
      key: '4',
      keyword: '招商',
      clicks: 2,
      impressions: 44,
      ctr: '4.5%'
    },
    {
      key: '5',
      keyword: '智能办公+空间',
      clicks: 12,
      impressions: 120,
      ctr: '10%'
    },
    {
      key: '6',
      keyword: '运营服务',
      clicks: 0,
      impressions: 77,
      ctr: '0%'
    },
    {
      key: '7',
      keyword: '电商直播',
      clicks: 10,
      impressions: 53,
      ctr: '18.8%'
    },
    {
      key: '8',
      keyword: 'To B',
      clicks: 5,
      impressions: 44,
      ctr: '11%'
    },
    {
      key: '9',
      keyword: '电视节目 综艺',
      clicks: 3,
      impressions: 62,
      ctr: '4.8%'
    },
    {
      key: '10',
      keyword: '直播平台资源',
      clicks: 12,
      impressions: 70,
      ctr: '17.1%'
    },
    {
      key: '11',
      keyword: '经济效益+社会效益',
      clicks: 22,
      impressions: 108,
      ctr: '20.3%'
    },
    {
      key: '12',
      keyword: '办公室租赁',
      clicks: 35,
      impressions: 277,
      ctr: '12.6%'
    },
    {
      key: '13',
      keyword: '场地 面积 价格',
      clicks: 61,
      impressions: 388,
      ctr: '15.7%'
    },
    {
      key: '14',
      keyword: '文化+创意',
      clicks: 28,
      impressions: 77,
      ctr: '36.3%'
    }
  ]


  useEffect(() => {
    getWeekDate(); // 获取近7天的日期
    getHotTrendsYData7Days(); // 获取近7天的y轴数据
    getMonthDate(); // 获取近30天的日期
    getHotTrendsYData30Days(); // 获取近30天的y轴数据
    getYearDate(); // 获取近12个月的日期
    getHotTrendsYData12Months(); // 获取近12个月的y轴数据
  }, [])

  // 热点趋势日期切换
  const hotTrendsDateChange = (e) => {
    let value = e.target.value;
    setHotTrendsDate(value);
    if (value === 'week') {
      setXdata(hotTrendsXData7Days);
      setYdata(hotTrendsYData7Days);
    } else if (value === 'month') {
      setXdata(hotTrendsXData30Days);
      setYdata(hotTrendsYData30Days);
    } else if (value === 'year') {
      setXdata(hotTrendsXData12Months);
      setYdata(hotTrendsYData12Months);
    }
  }

  // 获取近7天y轴数据
  const getHotTrendsYData7Days = () => {
    const weekData = [];
    for (let i = 0; i < 7; i++) {
      weekData.push(randomNum(3, 20));
    }
    setHotTrendsYData7Days(weekData);
    setYdata(weekData);
  }

  // 获取近30天y轴数据
  const getHotTrendsYData30Days = () => {
    const monthData = [];
    for (let i = 0; i < 30; i++) {
      monthData.push(randomNum(40, 100));
    }
    setHotTrendsYData30Days(monthData);
  }

  // 获取近12个月y轴数据
  const getHotTrendsYData12Months = () => {
    const yearData = [];
    for (let i = 0; i < 12; i++) {
      yearData.push(randomNum(500, 2000));
    }
    setHotTrendsYData12Months(yearData);
  }


  // 定义0-300的随机数
  const randomNum = (min, max) => {
    // return Math.floor(Math.random() * num);
    return Math.floor(Math.random() * (max - min + 1) + min);
  }
  // 定义近7天的日期
  const getWeekDate = () => {
    const date = new Date();
    const weekDate = [];
    for (let i = 0; i < 7; i++) {
      date.setDate(date.getDate() - 1);
      weekDate.unshift(date.toLocaleDateString());
    }
    setHotTrendsXData7Days(weekDate);
    setXdata(weekDate);
  }
  // 定义近30天的日期
  const getMonthDate = () => {
    const date = new Date();
    const monthDate = [];
    for (let i = 0; i < 30; i++) {
      date.setDate(date.getDate() - 1);
      monthDate.unshift(date.toLocaleDateString());
    }
    setHotTrendsXData30Days(monthDate)
    
  }
  // 定义近12个月的日期
  const getYearDate = () => {
    const date = new Date();
    const yearDate = [];
    for (let i = 0; i < 12; i++) {
      date.setMonth(date.getMonth() - 1);
      yearDate.unshift(date.toLocaleDateString());
    }
    setHotTrendsXData12Months(yearDate);
  }


  return (
    <>
      <Row gutter={[32, 32]}>
        <Col span={6}>
          <Card
            title="站点访问量（月）"
            actions={[
              <div className='card-footer-wrap flexstart'>
                <div className='card-footer-label'>总访问量</div>
                <div className='card-footer-value'>233,841</div>
              </div>,
              <div className='card-footer-wrap flexend'>
                <div>同比</div>
                <div className='card-footer-up'><ArrowUpOutlined />12.8%</div>
              </div>
            ]}
          >
            <Statistic value={12893} className='num-statis' />
            <LineCharts chartData={visitData} />
          </Card>
        </Col>
        <Col span={6}>
          <Card
            title="活动数量（月）"
            actions={[
              <div className='card-footer-wrap flexstart'>
                <div className='card-footer-label'>活动总数</div>
                <div className='card-footer-value'>109</div>
              </div>,
              <div className='card-footer-wrap flexend'>
                <div>同比</div>
                <div className='card-footer-down'><ArrowDownOutlined />1%</div>
              </div>
            ]}
          >
            <Statistic value={13} className='num-statis' />
            <LineCharts chartData={activityData} />
          </Card>
        </Col>
        <Col span={6}>
          <Card
            title="作品数量（月）"
            actions={[
              <div className='card-footer-wrap flexstart'>
                <div className='card-footer-label'>作品总数</div>
                <div className='card-footer-value'>216</div>
              </div>,
              <div className='card-footer-wrap flexend'>
                <div>同比</div>
                <div className='card-footer-up'><ArrowUpOutlined />35%</div>
              </div>
            ]}
          >
            <Statistic value={71} className='num-statis' />
            <LineCharts chartData={worksData} />
          </Card>
        </Col>
        <Col span={6}>
          <Card
            title="入驻企业总数"
            actions={[
              <div className='card-footer-wrap flexstart'>
                <div className='card-footer-label'>历史总数</div>
                <div className='card-footer-value'>335</div>
              </div>
            ]}
          >
            <Statistic value={48} className='num-statis' />
            <LineCharts chartData={[]} />
          </Card>
        </Col>
        <Col span={24}>
          <Card
            title="热点趋势"
            extra={
              <Radio.Group onChange={hotTrendsDateChange} defaultValue={hotTrendsDate} buttonStyle="solid">
                <Radio.Button value="week">最近7天</Radio.Button>
                <Radio.Button value="month">最近30天</Radio.Button>
                <Radio.Button value="year">最近12个月</Radio.Button>
              </Radio.Group>
            }
          >
            <LineChart2 xdata={xdata} ydata={ydata} />
          </Card>
        </Col>
        <Col span={24}>
          <Card>
            <Table columns={columns} dataSource={dataSources} />
          </Card>
        </Col>
      </Row>
    </>
  )
};

export default Home;