import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Link } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { Menu } from "antd";
import {
  PieChartFilled,
  FireFilled,
  PlaySquareFilled,
  FolderAddFilled,
  ProductFilled,
  ContactsFilled,
  NotificationFilled
} from '@ant-design/icons';

import './menu.scss';

function MenuComponent() {

  const location = useLocation();
  const [selectedKeys, setSelectedKeys] = useState([location.pathname]);

  useEffect(() => {
    setSelectedKeys([location.pathname]);
  }
  , [location.pathname]);


  return (
    <>
      <Menu
        className="menu-wrap"
        mode="inline"
        selectedKeys={selectedKeys}
        items={[
          {
            key: '/',
            icon: <PieChartFilled />,
            label: <Link to={'/'}>统计面板</Link>,
          },
          {
            key: '/activity',
            icon: <FireFilled />,
            label: <Link to={'/activity'}>活动管理</Link>,
          },
          {
            key: '/news',
            icon: <NotificationFilled />,
            label: <Link to={'/news'}>企业动态</Link>,
          },
          {
            key: '/works',
            icon: <PlaySquareFilled />,
            label: <Link to={'/works'}>优秀作品</Link>,
          },
          {
            key: '/company',
            icon: <ProductFilled />,
            label: <Link to={'/company'}>入驻企业</Link>,
          },
          {
            key: '/users',
            icon: <ContactsFilled />,
            label: <Link to={'/users'}>用户管理</Link>,
          },
          {
            key: '/material',
            icon: <FolderAddFilled />,
            label: <Link to={'/material'}>素材库</Link>,
          }
        ]}
      ></Menu>
    </>
  )
};

export default observer(MenuComponent);