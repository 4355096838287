import { useState } from 'react';
import { Table, Card, Button, Modal, Form, Input, DatePicker, message, Select } from 'antd';
import './activity.scss';
import dayjs from 'dayjs';

function Activity() {
  const [visible, setVisible] = useState(false);
  const [form] = Form.useForm();
  const [tableData, setTableData] = useState([
    {
      id: 1,
      name: '“热辣女神 滚烫人生”女神节活动完美落幕',
      description: '她是谁？是母亲、是妻子、是女儿、是闺蜜…她扮演着众多身份，她顶起半边天而她，也是自己温柔、美丽、快乐、洒脱笑颜如花、光芒万丈2024年3月6日马栏山众创园一楼我们共同见证了一场别开生面的女神节活动',
      time: '2024-03-08',
      address: '马栏山众创园一楼大厅',
      number: 100,
      status: '已结束'
    },
    {
      id: 2,
      name: '“魅力潇湘·巨变乡村”开福区统一战线助力城步县乡村振兴“多点开花”',
      description: '长沙市开福区统一战线赴邵阳市城步苗族自治县开展“魅力潇湘·巨变乡村”主题调研活动，发挥统战优势助力城步县乡村振兴。',
      time: '2023-07-13',
      address: '城步县',
      number: 50,
      status: '已结束'
    },
    {
      id: 3,
      name: '“产”开聊以更大力度更实举措推进企业高质量发展',
      description: '马栏山视频文创园开展“下午茶·‘产’开聊”第五期活动，园区党工委书记邹犇淼与园区七家企业代表就产业政策、产业发展等问题面对面畅聊。党工委委员、管委会副主任胡朝晖，党工委委员、纪工委书记、监察工委主任浣琳出席，相关局室负责人参加。',
      time: '2023-09-02',
      address: '马栏山众创园一楼大会议室',
      number: 20,
      status: '已结束'
    },
    {
      id: 4,
      name: '主题沙龙：如何玩转短视频，打造好创始人IP?',
      description: '本期沙龙特邀头等传媒X火熊计划MCN联合创始人晨飞老师，从做好账号定位、搭建短视频团队，到打造爆款内容等多个角度，对如何玩转短视频进行了全方位的实操解析，满满的干货让参与者受益颇丰',
      time: '2023-09-07',
      address: '马栏山众创园一楼大会议室',
      number: 80,
      status: '已结束'
    },
    {
      id: 5,
      name: '“统战青年说”2023年第5场',
      description: '由长沙市委统战部主办的“统战青年说”2023年第5场如期在马栏山众创园举行，6位青年围绕“做好网络统战工作 走好网络群众路线”提出了见解和思考。',
      time: '2023-08-05',
      address: '马栏山众创园一楼大厅',
      number: 40,
      status: '已结束'
    },
    {
      id: 6,
      name: '融合十年 笃行致远——2023中国新媒体大会',
      description: '开幕式暨主论坛、内容创新论坛、国际传播论坛等6场平行论坛、“马栏山时间”文创活动、中国新媒体技术展等4场主题活动，盘点梳理十年来新闻战线坚持以习近平新时代中国特色社会主义思想为指引，主力军全面挺进主战场、做大做强新型主流媒体的成果。',
      time: '2023-07-13',
      address: '马栏山众创园一楼大厅',
      number: 200,
      status: '已结束'
    },
    {
      id: 7,
      name: '开福“未来合伙人”实习实践主题活动见面会',
      description: '入选开福“未来合伙人”，即可享受由区人才经费保障的最长3个月每人每月2000元的生活补贴和每人一次性1000元的交通补贴，另根据不同企业实际，还将分别提供工作餐、住宿、实习津贴等福利。毕业后不仅能够优先到相关实习企业工作，并且可被择优推荐为相应层次的区级人才，享受各项优惠政策。',
      time: '2023-07-20',
      address: '马栏山众创园一楼大厅',
      number: 80,
      status: '已结束'
    },
    {
      id: 8,
      name: '《管理初体验》——给中小企业一线管理者的第一堂管理公开课',
      description: '《管理初体验》是泰锐普咨询为中小企业一线管理者开发的专属项目，通过测评分析-课堂学习-应用训练三阶段，帮助一线管理者认可“既要带团队又要做业务”的角色定位，并掌握基本管理工具与方法，最终帮助企业实现个人与团队业绩双增长。',
      time: '2023-09-01',
      address: '马栏山众创园一楼大厅',
      number: 50,
      status: '已结束'
    },
    {
      id: 9,
      name: '“围炉煮茶 共话发展”马栏山电商企业家沙龙活动在园区举办',
      description: '晚来天欲雪，能饮一杯无?小雪时节，天气虽寒，但我们的热情不减，在这个充满诗意与氛围的节气，草莓V视特举办“围炉煮茶，共话发展”马栏山电商企业家沙龙活动。此次沙龙活动，我们邀请到园区电商企业家，分享企业的成长故事，探讨行业的发展趋势。',
      time: '2023-11-22',
      address: '马栏山众创园一楼大厅',
      number: 100,
      status: '已结束'
    },
    {
      id: 10,
      name: '向世界讲好中国故事精彩长沙篇章',
      description: '2021年长沙市十大对外传播优秀案例、外宣品、外宣图片及第五届“城市之光·长沙魅力”短视频大赛获奖作品受到表彰，2022年度“人人都是长沙城市推荐官”倡议发布，“长沙一分钟”外宣传播正式对外首发，相关外宣协作单位作经验交流。',
      time: '2023-07-12',
      address: '长沙大学红色影厅',
      number: 200,
      status: '已结束'
    }
  ]);

  const columns = [
    {
      title: '序号',
      dataIndex: 'index',
      key: 'index',
      width: 60,
      align: 'center',
      render: (text, record, index) => index + 1
    },
    {
      title: '活动名称',
      dataIndex: 'name',
      key: 'name'
    },
    {
      title: '活动描述',
      dataIndex: 'description',
      key: 'description',
      width: 500
    },
    {
      title: '活动时间',
      dataIndex: 'time',
      key: 'time'
    },
    {
      title: '活动地点',
      dataIndex: 'address', // 一个是马栏山众创园一楼大厅，一个是马栏山众创园一楼大会议室
      key: 'address'
    },
    {
      title: '活动人数',
      dataIndex: 'number',
      key: 'number'
    },
    {
      title: '活动状态',
      dataIndex: 'status', // 未开始、进行中、已结束
      key: 'status'
    },
  ]
  // 添加活动事件
  const addClick = () => {
    setVisible(true);
    form.resetFields();
  }
  // 确认添加事件
  const handleOk = () => {
    form.validateFields().then(values => {
      let data = {
        id: tableData.length + 1,
        ...values,
        time: dayjs(values.time).format('YYYY-MM-DD'),
        status: '未开始'
      }
      setTableData([data, ...tableData]);
      message.open({
        type: 'success',
        content: '添加成功'
      })
      setVisible(false);
    }).catch(err => {
      message.open({
        type: 'error',
        content: '请检查表单填写是否正确'
      })
    });
  }
  // 取消添加事件
  const handleCancel = () => {
    setVisible(false);
    form.resetFields();
  }

  const disabledDate = (current) => {
    // Can not select days before today and today
    return current && current < dayjs().endOf('day');
  };

  return (
    <div>
      <Card
        title="活动列表"
        extra={
          <Button type='primary' className='addBtn' onClick={addClick}>添加活动</Button>
        }
      >
        <Table 
          className='activity-table' 
          rowKey={(record) => record.id} 
          columns={columns} 
          dataSource={tableData} 
        />
      </Card>

      <Modal
        title="添加活动"
        open={visible}
        destroyOnClose={true}
        footer={
          [
            <Button key="back" onClick={handleCancel} className='modalFooterBtn'>取消</Button>,
            <Button key="submit" type="primary" onClick={handleOk} className='modalFooterBtn'>确认</Button>
          ]
        }
      >
        <Form
          form={form}
          labelCol={{ span: 4 }}
          wrapperCol={{ span: 18 }}
          preserve={false}
        >
          <Form.Item
            label="活动名称"
            name="name"
            rules={[{ required: true, message: '请输入活动名称' }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="活动描述"
            name="description"
            rules={[{ required: true, message: '请输入活动描述' }]}
          >
            <Input.TextArea />
          </Form.Item>
          <Form.Item
            label="活动时间"
            name="time"
            rules={[{ required: true, message: '请选择活动时间' }]}
          >
            <DatePicker
              format="YYYY-MM-DD"
              disabledDate={disabledDate}
            />
          </Form.Item>
          <Form.Item
            label="活动地点"
            name="address"
            rules={[{ required: true, message: '请输入活动地点' }]}
          >
            <Select placeholder="选择活动地点" options={[
              {
                label: '马栏山众创园一楼大厅',
                value: '马栏山众创园一楼大厅'
              },
              {
                label: '马栏山众创园一楼大会议室',
                value: '马栏山众创园一楼大会议室'
              }
            ]}></Select>
          </Form.Item>
          <Form.Item
            label="活动人数"
            name="number"
            rules={[{ required: true, message: '请输入活动人数' }]}
          >
            <Input />
          </Form.Item>

        </Form>
      </Modal>
    </div>
  );
}

export default Activity;