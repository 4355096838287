import { observer } from 'mobx-react-lite';
import './logo.scss';
import useStore from '@/store';

function Logo() {
  const { isCollapsed } = useStore().stateStore;
  return (
    <>
      <div className="logo-wrap">
        <div className='logo-name'>天衍文化</div>
        {
          isCollapsed ? null : <div className='sys-name'>服务平台管理后台</div>
        }
      </div>
    </>
  )
};

export default observer(Logo);