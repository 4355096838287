import { useState } from 'react';
import { Table, Card, Button, Modal, Form, Input, DatePicker, message, Select } from 'antd';

import dayjs from 'dayjs';
import './works.scss';
import { render } from '@testing-library/react';
function Works() {
  const [visible, setVisible] = useState(false);
  const [form] = Form.useForm();
  const [tableData, setTableData] = useState([
    {
      id: 1,
      name: '乡村合伙人',
      cover: 'https://sadmin.cmvstv.com/upload/system/20190716/201907161026007168.jpg',
      company: '湖南博胜互动文化传媒有限公司',
      description: '"湖南省文化旅游厅联合湖南广播电视台以及园区企业湖南博胜互动文化传媒有限公司共同推出中国首档乡村振兴·文旅纪实节目《乡村合伙人》，积极响应乡村振兴的号召，节目在湖南省两万多个乡村中选择26个特色乡村，打造26种湖南乡村振兴的模式。"      ',
      playUrl: 'https://cloud.video.taobao.com/play/u/2816746226/p/2/e/6/t/1/231138021826.mp4',
      time: '2024-06-01'
    },
    {
      id: 2,
      name: '《五十公里桃花坞-北京》',
      cover: 'https://sadmin.cmvstv.com/upload/system/20230805/202308050304445552.jpg',
      company: '皙悦传媒',
      description: '"《五十公里桃花坞-北京》是腾讯视频联合园区企业皙悦传媒推出的群居生活体验类真人秀，由陈陈陈、郭麒麟、赖冠霖、辣目洋子、李雪琴、孟子义、欧欧、彭楚粤、宋丹丹、苏芒、舒淇、汪苏泷、张翰、周杰、周也担任固定嘉宾，共10期  。\r\n节目中，15位居民共同在距离城市50公里的桃花坞，构建一个集艺术、人文、科技于一身的理想社区，打破孤独、共同生活21天，见证友谊的生根发芽  。"',
      playUrl: '"https://outin-ab0b807a303d11ee964d00163e1c955c.oss-cn-shanghai.aliyuncs.com/sv/171a7a80-189c47f29df/171a7a80-189c47f29df.m4v?Expires=1691222485&OSSAccessKeyId=LTAIxSaOfEzCnBOj&Signature=E5LnDS2hJKGRKjwRg2V4U6Evx%2F0%3D"',
      time: '2024-06-01'
    },
    {
      id: 3,
      name: '文化长沙',
      cover: 'https://sadmin.cmvstv.com/upload/system/20190717/201907171037002449.jpg',
      company: '星辰在线',
      description: '"由中共长沙市委宣传部、星辰在线联合出品的长沙形象宣传片《文化长沙》惊艳亮相。“8分钟读懂长沙”的精彩不仅震撼了每个生活在这里的人，还将“感知中国一湖南文化走进法国”中法文化交流活动现场展播，让“文化长沙”惊艳全球。《文化长沙》囊括了长沙重点非物质遗产、文化标识、遗址、文化活动等近50个项目，包括驰名中外的湘绣、宁乡皮影戏、岳麓书院、白沙古井、天心阁、爱晚亭、贾谊故居等，以图片画面与实拍视频影像相融合的方式，全方位展现长沙的地理环境、历史发展、建筑文化、饮食文化、风俗民情、文化特色及长沙精神，让观众在“湘人”、“湘情”、“湘味”、“湘艺”中，感受星城长沙的文化之美。"',
      playUrl: 'https://cloud.video.taobao.com/play/u/2816746226/p/2/e/6/t/1/231055596565.mp4',
      time: '2024-06-01'
    },
    {
      id: 4,
      name: '芒果小戏骨剧团《十八洞》',
      cover: 'https://sadmin.cmvstv.com/upload/system/201903270420321683.jpg',
      company: '湖南破冰素质教育发展有限公司',
      description: '大型原创少儿版话剧《十八洞》“精准扶贫”共圆全面小康的中国梦，跨越一个时期的空白，延续一个时代的精神。',
      playUrl: 'https://sadmin.cmvstv.com/upload/video/21.mp4',
      time: '2024-06-01'
    },
    {
      id: 5,
      name: '我的青春在丝路',
      cover: 'https://sadmin.cmvstv.com/upload/system/201903060436267917.jpg',
      company: '湖南卡特文化传媒有限公司',
      description: '《我的青春在丝路》是由芒果TV出品，以及园区企业湖南卡特文化传媒有限公司进行的后期制作，联合摄制的主旋律纪录片。一共五集，每集讲述一个在“一带一路”沿线国家追寻青春梦想的年轻人的故事，节目于2018年3月1日-5日每日十点在芒果TV播出。',
      playUrl: 'https://cloud.video.taobao.com/play/u/2816746226/p/2/e/6/t/1/228411129036.mp4',
      time: '2024-06-01'
    },
    {
      id: 6,
      name: '博物奇妙夜',
      cover: 'https://sadmin.cmvstv.com/upload/system/20190716/201907161026573263.jpg',
      company: '湖南慢点娱乐文化传媒有限公司',
      description: '《博物奇妙夜》是爱奇艺独播,联合园区企业湖南慢点娱乐文化传媒有限公司制作的首档实景文博谈话节目。首季精选世界文化遗产云冈石窟、丝路起点上的陕西历史博物馆、故宫清明上河图特展以及观复博物馆，借助“博物”的概念用“奇妙”的当代视角，解读有质感的文化、品尝有滋味的历史。',
      playUrl: 'https://cloud.video.taobao.com/play/u/2816746226/p/2/e/6/t/1/231233258845.mp4',
      time: '2024-06-01'
    },
    {
      id: 7,
      name: '长沙，别来无恙',
      cover: 'https://sadmin.cmvstv.com/upload/system/20200421/202004210134558625.jpg',
      company: '湖南智创视通企业管理运营有限公司',
      description: '你见过 这样的长沙吗？ 工作日的傍晚 没有汽笛 没有熙攘 国金街安静冷清 五一路店门紧闭 人们不轻易对话 像在扮演黑白默剧 整座城市的一切 都被慢速播放 从湖北到湖南 连着一衣带水的地缘 同一条长江 共一湖洞庭 物资善意穿越关口 医疗队伍百里救急 长沙与武汉 成为密不可分的 生命共同体 你看 冬眠的长沙 正在慢慢等待苏醒 你听 安静的长沙 正在等着高唱天明 再等一等 等长沙回到正常倍速 再去不夜的解放西 去坡子街吃火宫殿 集满茶颜悦色的积分卡 把太平老街走九遍 看一看橘子洲头的焰火 逛一逛文和友里的老长沙 别害怕 别着急 长沙就在那里 等待破晓后的苏醒 吃得苦 霸得蛮 耐得烦 长沙精神 从嘴皮传到心底 长沙灯火 从街头亮到心头 蛰伏冬眠后 等你一句久违的 “恰末子克咯”',
      playUrl: 'https://cloud.video.taobao.com/play/u/2816746226/p/2/e/6/t/1/254002216346.mp4',
      time: '2022-01-12'
    },
    {
      id: 8,
      name: '重启味蕾，开启舌尖上的幸福',
      cover: 'https://sadmin.cmvstv.com/upload/system/20200421/202004210139317794.jpg',
      company: '湖南智创视通企业管理运营有限公司',
      description: '3月7日 长沙市开福区召开餐饮酒店业复工复产工作座谈会 区委书记曹再兴现场推出实际帮“复”举措 并针对企业缺乏防疫物资、融资难、用工难 等问题进行了安排部署 统筹各企业继续做好疫情防控的同时全面投入复工复产 开福区政府搭台 线上平台运营 辖区内商企力挺 媒体鼓劲助力 在开福区 一场元气满满的消费盛宴 成为了这个春天最暖的起点',
      playUrl: 'https://cloud.video.taobao.com/play/u/2816746226/p/2/e/6/t/1/258942305589.mp4',
      time: '2022-03-07'
    },
    {
      id: 9,
      name: '复工复产不负时光',
      cover: 'https://sadmin.cmvstv.com/upload/system/20200421/202004210147079842.jpg',
      company: '长沙荣耀文化传播有限公司',
      description: '这个春节，过得不太平静 安静的街道、疏离的人群 但好在，总还有希望 在不远处与我们挥手相望 从武汉到长沙 湖北湖南一衣带水 不同坐标，却有相同的期许和关切 你见过 这样的长沙吗？ 工作日的傍晚 没有汽笛 没有熙攘 国金街安静冷清 五一路店门紧闭 人们不轻易对话 像在扮演黑白默剧 整座城市的一切 都被慢速播放 从湖北到湖南 连着一衣带水的地缘 同一条长江 共一湖洞庭 物资善意穿越关口 医疗队伍百里救急 长沙与武汉 成为密不可分的 生命共同体 你看 冬眠的长沙 正在慢慢等待苏醒 你听 安静的长沙 正在等着高唱天明 再等一等 等长沙回到正常倍速 再去不夜的解放西 去坡子街吃火宫殿 集满茶颜悦色的积分卡 把太平老街走九遍 看一看橘子洲头的焰火 逛一逛文和友里的老长沙 别害怕 别着急 长沙就在那里 等待破晓后的苏醒 吃得苦 霸得蛮 耐得烦 长沙精神 从嘴皮传到心底 长沙灯火 从街头亮到心头 蛰伏冬眠后 等你一句久违的 “恰末子克咯”',
      playUrl: 'https://cloud.video.taobao.com/play/u/2816746226/p/2/e/6/t/1/259949890290.mp4',
      time: '2023-03-01'
    },
    {
      id: 10,
      name: '等你，长沙，回归正常倍速',
      cover: 'https://sadmin.cmvstv.com/upload/system/20200421/202004210153354459.jpg',
      company: '松鼠（湖南）文化传媒有限公司',
      description: '新型冠状病毒的爆发，让我们看到了社会上的百态，光明与黑暗总是交替上演着。 朋友圈不断转发的文章，微博中密密麻麻的热搜。一切的一切，都在用量化的方式，用一排排数字和猩红的地图，提醒我们：这一场战役，关乎你，也关乎我。 而这座城，终究在千万长沙人的祈祷中逐渐复苏。我们将这一切用镜头记录，不为暂停而伤感，不为恢复而激动，仅仅留下这段特殊的记忆，是警钟，也是思考。多年后，我们依旧会铭记这段特殊的日子. 在城市复苏的道路上，我们总是能看见很多人的身影。他们或许是每日为你测量体温的社区人员、是风水雨打也会准时将食物送达的外卖小哥、是坚持给长沙城带来整洁的环卫工…… 他们看似普通，却是我们日常生活里千千万万个的缩影。他们用自己的力量，为回归正常生活努力、为城市带来温暖和快乐！ 如今这座城市已逐渐恢复了生机，疫情虽未消停，我们将铭记这段日子，感恩身边的每一位默默付出的人，珍惜我们所爱的亲人好友 用此片记录身边朋友那些平凡的想法跟愿望，2020，我们重获生命的意义。',
      playUrl: 'https://cloud.video.taobao.com/play/u/2816746226/p/2/e/6/t/1/259782569603.mp4',
      time: '2023-04-01'
    },
    {
      id: 11,
      name: '太酷了设计师',
      cover: 'https://sadmin.cmvstv.com/upload/system/20190618/201906180626152536.mpg_20190611_121813',
      company: '长沙妙趣新媒体技术有限公司',
      description: '太酷了设计师',
      playUrl: 'https://cloud.video.taobao.com/play/u/2816746226/p/2/e/6/t/1/228338424724.mp4',
      time: '2024-01-12'
    },
    {
      id: 12,
      name: '喵爸村',
      cover: 'https://sadmin.cmvstv.com/upload/system/20190618/201906180628054820.mp4_20190611_115703',
      company: '长沙妙趣新媒体技术有限公司',
      description: '喵爸村',
      playUrl: 'https://cloud.video.taobao.com/play/u/2816746226/p/2/e/6/t/1/228411781968.mp4',
      time: '2024-04-01'
    },
    {
      id: 13,
      name: '《静享汝城》郴州汝城旅游',
      cover: 'https://sadmin.cmvstv.com/upload/system/20190618/201906180630104809.jpg',
      company: '草莓V视',
      description: '《静享汝城》郴州汝城旅游',
      playUrl: 'https://cloud.video.taobao.com/play/u/2816746226/p/2/e/6/t/1/50068056522.mp4',
      time: '2024-01-01'
    },
    {
      id: 14,
      name: '变形计',
      cover: 'https://sadmin.cmvstv.com/upload/system/201903060554481378.jpg',
      company: '湖南定一文化传播有限公司',
      description: '变形计第五季',
      playUrl: 'https://sadmin.cmvstv.com/upload/video/6.mp4',
      time: '2024-01-10'
    },
    {
      id: 15,
      name: '星辰塔尖',
      cover: 'https://sadmin.cmvstv.com/upload/system/201903131239296246.jpg',
      company: '湖南星辰在线新媒体有限公司',
      description: '星辰塔尖',
      playUrl: 'https://sadmin.cmvstv.com/upload/video/9.mp4',
      time: '2024-01-01'
    },
    {
      id: 16,
      name: '功守道宣传片',
      cover: 'https://sadmin.cmvstv.com/upload/system/201903060533234543.jpg',
      company: '长沙妙趣新媒体技术有限公司',
      description: '功守道宣传片',
      playUrl: 'https://sadmin.cmvstv.com/upload/video/10.mp4',
      time: '2024-01-01'
    },
    {
      id: 17,
      name: '火星幼儿园',
      cover: 'https://sadmin.cmvstv.com/upload/system/201903060545179140.jpg',
      company: '长沙妙趣新媒体技术有限公司',
      description: '火星幼儿园',
      playUrl: 'https://sadmin.cmvstv.com/upload/video/13.mp4',
      time: '2024-01-01'
    },
    {
      id: 18,
      name: '2018淘宝造物节',
      cover: 'https://sadmin.cmvstv.com/upload/system/201903060548513106.jpg',
      company: '长沙妙趣新媒体技术有限公司',
      description: '2018淘宝造物节',
      playUrl: 'https://sadmin.cmvstv.com/upload/video/14.mp4',
      time: '2024-01-01'
    },
    {
      id: 19,
      name: '平凡之路之青春你好',
      cover: 'https://sadmin.cmvstv.com/upload/system/201903060550596140.jpg',
      company: '湖南视海影业有限公司',
      description: '平凡之路之青春你好',
      playUrl: 'https://sadmin.cmvstv.com/upload/video/15.mp4',
      time: '2024-01-01'
    },
    {
      id: 20,
      name: '送一百个女孩回家',
      cover: 'https://sadmin.cmvstv.com/upload/system/201903060552203782.jpg',
      company: '湖南卡特文化传媒有限公司',
      description: '送一百个女孩回家',
      playUrl: 'https://sadmin.cmvstv.com/upload/video/16.mp4',
      time: '2024-01-01'
    },
    {
      id: 21,
      name: '勇敢的世界',
      cover: 'https://sadmin.cmvstv.com/upload/system/201903060556013627.jpg',
      company: '湖南定一文化传播有限公司',
      description: '勇敢的世界',
      playUrl: 'https://sadmin.cmvstv.com/upload/video/17.mp4',
      time: '2024-01-01'
    },
    {
      id: 22,
      name: '北京银行邻里一家APP宣传视频',
      cover: 'https://sadmin.cmvstv.com/upload/system/201903270412207732.jpg',
      company: '长沙市大麦文化传播有限公司',
      description: '北京银行邻里一家APP宣传视频',
      playUrl: 'https://sadmin.cmvstv.com/upload/video/20.mp4',
      time: '2024-01-01'
    },
    {
      id: 23,
      name: '芒果爱你一起全国少儿戏剧文化艺术节',
      cover: 'https://sadmin.cmvstv.com/upload/system/201903270421028000.jpg',
      company: '湖南破冰素质教育发展有限公司',
      description: '开创少儿戏剧电视真人秀之先河，成为最具影响力的少儿戏剧节活动之一，绽放出戏剧艺术的力量，打造成为中国最具互动和创新精神的少儿戏剧活动平台，以长沙为中心辐射全国，共同传承戏剧教育理念，创新教育戏剧模式，再创时代传奇',
      playUrl: 'https://v.qq.com/x/page/t0837ocs3ie.html',
      time: '2024-01-01'
    },
    {
      id: 24,
      name: '考不好没关系',
      cover: 'https://sadmin.cmvstv.com/upload/system/20190618/201906180629186995.mp4_20190611_115139',
      company: '卡特传媒',
      description: '考不好没关系',
      playUrl: 'https://cloud.video.taobao.com/play/u/2816746226/p/2/e/6/t/1/228493062251.mp4',
      time: '2024-01-01'
    },
    {
      id: 25,
      name: '《冲锋吧！少年》第二期',
      cover: 'https://sadmin.cmvstv.com/upload/system/20190805/201908050506065423.jpg',
      company: '湖南破冰素质教育发展有限公司',
      description: '《冲锋吧！少年》第二期',
      playUrl: 'https://v.qq.com/x/page/k07892wdmh5.html',
      time: '2024-01-01'
    },
    {
      id: 26,
      name: '主力说小故事',
      cover: 'https://sadmin.cmvstv.com/upload/system/20190805/201908050555434101.jpg',
      company: '长沙易及传媒有限公司',
      description: '世界上一个不为人知的有趣小故事：一个连死神都奈何不了的人！',
      playUrl: 'http://v.douyin.com/AtVLc7/',
      time: '2024-01-01'
    },
    {
      id: 27,
      name: '小锅方了',
      cover: 'https://sadmin.cmvstv.com/upload/system/20190805/201908050602059900.jpg',
      company: '长沙易及传媒有限公司',
      description: '原创动漫悬疑解谜小故事：哈士奇之死',
      playUrl: 'http://v.douyin.com/AtKtKE/',
      time: '2024-01-01'
    },
    {
      id: 28,
      name: '易及书库',
      cover: 'https://sadmin.cmvstv.com/upload/system/20190805/201908050603106524.jpg',
      company: '长沙易及传媒有限公司',
      description: '用有趣的风格讲述人类未来三十年的变化：人类未来三十年',
      playUrl: 'http://v.douyin.com/AtgTKs/',
      time: '2024-01-01'
    },
    {
      id: 29,
      name: '垃圾处理器',
      cover: 'https://sadmin.cmvstv.com/upload/system/20190807/201908071023105598.jpg',
      company: '湖南好频购网络科技有限公司',
      description: '垃圾处理器',
      playUrl: 'https://cloud.video.taobao.com/play/u/2816746226/p/2/e/6/t/1/233132014067.mp4',
      time: '2024-01-01'
    },
    {
      id: 30,
      name: '双效一体拖把',
      cover: 'https://sadmin.cmvstv.com/upload/system/20190807/201908071023384663.jpg',
      company: '湖南好频购网络科技有限公司',
      description: '双效一体拖把',
      playUrl: 'https://cloud.video.taobao.com/play/u/2816746226/p/2/e/6/t/1/233132258585.mp4',
      time: '2024-01-01'
    },
    {
      id: 31,
      name: '大师定制梅菜扣肉',
      cover: 'https://sadmin.cmvstv.com/upload/system/20190807/201908071024028015.jpg',
      company: '湖南好频购网络科技有限公司',
      description: '大师定制梅菜扣肉',
      playUrl: 'https://cloud.video.taobao.com/play/u/2816746226/p/2/e/6/t/1/233027985038.mp4',
      time: '2024-01-01'
    },
    {
      id: 32,
      name: '送一百位女孩回家',
      cover: 'https://sadmin.cmvstv.com/upload/system/20190808/201908080254222217.jpg',
      company: '湖南慢点娱乐文化传媒有限公司',
      description: '《送一百位女孩回家》第一季共有十二期节目，丁丁张通过陪伴女孩下班路上的时光，来探寻不同都市女性的生存状态和成长心路。这些女孩中有身份特殊的女明星或者超级模特，高学历的游戏主播，收入过亿的网红少女，也有普通的上班族。女孩们将她们最真实的漂泊生活通过镜头传递给观众，同时描绘出都市女性群体生活中的羁绊和成长。',
      playUrl: 'https://tv.sohu.com/v/MjAxODA2MTIvbjYwMDU0MjA2MS5zaHRtbA==.html',
      time: '2024-01-01'
    },
    {
      id: 33,
      name: '你好妈妈',
      cover: 'https://sadmin.cmvstv.com/upload/system/20190808/201908080257278824.jpg',
      company: '湖南慢点娱乐文化传媒有限公司',
      description: '《你好妈妈》是著名主持人李小萌“新人伦主义”节目矩阵“三部曲”中继《你好爸爸》之后的第二弹。节目将走进敬一丹，邓亚萍、刘璇、霍思燕、曹曦文、孙茜，黄澜等十二位新时代母亲的内心，从不同视角去探索人类最柔软最深情的母子关系，思索“母爱管理”的分寸与界限。',
      playUrl: 'https://www.jpkcnet.com/new/',
      time: '2024-01-01'
    },
    {
      id: 34,
      name: '镇店之宝',
      cover: 'https://sadmin.cmvstv.com/upload/system/20190808/201908080317466644.jpg',
      company: '湖南快乐淘宝文化传播有限公司',
      description: '镇店之宝',
      playUrl: 'https://cloud.video.taobao.com/play/u/2816746226/p/2/e/6/t/1/233230275323.mp4',
      time: '2024-01-01'
    },
    {
      id: 35,
      name: '我是创业者',
      cover: 'https://sadmin.cmvstv.com/upload/system/20190809/201908090335065494.jpg',
      company: '湖南省芒果大微文化传媒有限公司',
      description: '中国首档社交电商自明星创业励志成长记 从电子商务到互联网+再到自媒体创业，越来越多人涌入了互联网， 扎入社交电商创业浪潮，《我是创业者》节目应运而生。在这里，将社交电商的行业逻辑和运营模式进行节目化呈现 全方位展示选手的魅力、活力、能力 体现社交电商创业者的智商、财商、情商 打造“大众创业，万众创新”浪潮中的超级大IP。',
      playUrl: 'https://www.mgtv.com/b/325035/4447945.html?fpa=se',
      time: '2024-01-01'
    },
    {
      id: 36,
      name: '跟着大微去旅行',
      cover: 'https://sadmin.cmvstv.com/upload/system/20190809/201908090335398207.jpg',
      company: '湖南省芒果大微文化传媒有限公司',
      description: '一场属于团队的“变形计”， 一次说走就走的“花儿与少年”， 一场暖心走心的“妻子的浪漫旅行”。',
      playUrl: 'https://v.qq.com/x/page/e0505hy5mx4.html?ptag=qqbrowser',
      time: '2024-01-01'
    },
    {
      id: 37,
      name: '大微直播间',
      cover: 'https://sadmin.cmvstv.com/upload/system/20190809/201908090336351090.jpg',
      company: '湖南省芒果大微文化传媒有限公司',
      description: '《大微直播间》是芒果大微内容智造极其 重要一环，整合全公司及湖南广电的内容制作和宣传优 势以及微电商行业的自媒体和社群资源，以“直播节目化，节目直播化”的宗旨将《芒果大微直播间》打造成 中国微电商行业最专业的直播节目平台，为客户提供最 专业、最完善的内容解决方案，品牌曝光引流、团队参 与录制、代理高效转化等问题一站式解决。',
      playUrl: 'http://www.yizhibo.com/l/P8uDpndrB7FQ5jM7.html?p_from=pRoom.AnchorReplay',
      time: '2024-01-01'
    },
    {
      id: 38,
      name: '带着爸妈去旅行 第二季',
      cover: 'https://sadmin.cmvstv.com/upload/system/20190809/201908090443393860.jpg',
      company: '湖南奈斯文化传播有限公司',
      description: '《带着爸妈去旅行》是江西卫视推出的明星亲情孝道真人秀，该节目弘扬中华孝道文化，倡导“子女守则”呼吁社会关注家庭，关爱父母。',
      playUrl: 'https://v.qq.com/detail/4/45575.html',
      time: '2024-01-01'
    },
    {
      id: 39,
      name: '《英雄联盟》真人秀、拜年秀后期',
      cover: 'https://sadmin.cmvstv.com/upload/system/20190809/201908090444458859.jpg',
      company: '湖南奈斯文化传播有限公司',
      description: '《英雄联盟玩很6》是《英雄联盟》六周年特别户外综艺节目。《英雄联盟拜年秀》是《英雄联盟》的新春贺岁综艺秀。',
      playUrl: 'https://v.qq.com/x/page/e0022lwjals.html?ptag=qqbrowser/https://v.qq.com/x/page/n0548qse34o.html?ptag=qqbrowser',
      time: '2024-01-01'
    },
    {
      id: 40,
      name: '暴走法条君',
      cover: 'https://sadmin.cmvstv.com/upload/system/20190809/201908090445154929.jpg',
      company: '湖南奈斯文化传播有限公司',
      description: '《暴走法条君》是优酷推出是一档剧情式吐槽脱口秀，节目高度模拟法庭结购以法庭的模式吐槽和审批当下网友身边的时事热点。',
      playUrl: 'https://v.youku.com/v_show/id_XMTU5MTM5MjkzNg==.html?spm=a2h1n.8261147.0.0',
      time: '2024-01-01'
    },
    {
      id: 41,
      name: '太酷了！设计师',
      cover: 'https://sadmin.cmvstv.com/upload/system/20190809/201908090537203609.jpg',
      company: '湖南省南子影视传媒有限公司',
      description: '2018年9月16日，综艺节目《太酷了!设计师》登陆东南卫视及腾讯视频、芒果TV等平台。节目组从全国集结了30位服装设计师，让观众打造“养成系”设计师。同时，同济大学设计创意学院、上海国际设计创新学院长娄永琪，中国十大服装设计师、中国服装设计师协会理事祁刚，米兰新美术学院教授、服装品牌MIAORAN创始人苗冉，潮牌NPC主理人李晨等时尚圈人士将担任“时尚评鉴官”。',
      playUrl: 'http://www.iqiyi.com/a_19rrh4ycwp.html',
      time: '2024-01-01'
    },
    {
      id: 42,
      name: '小骑手冲啊',
      cover: 'https://sadmin.cmvstv.com/upload/system/20190809/201908090538587836.jpg',
      company: '湖南省南子影视传媒有限公司',
      description: '节目中，12名来自全国11个城市的宝宝集结在海口，接受为期11天的训练生活计划。这期间，他们不但要经历艰苦的训练，还要完成在骑士城堡的集体生活，共同抵御轮番空降的超级高手“黑骑士”的挑战。节目进行的同时，全国8大城市将举办8场城市选拔赛，8位城市冠军将组成“黄金骑士团”，向训练营中的“追风骑士团”发起终极挑战。',
      playUrl: 'https://www.iqiyi.com/v_19rskgcek8.html?vfm=m_303_qqll',
      time: '2024-01-01'
    },
    {
      id: 43,
      name: '七彩飘香',
      cover: 'https://sadmin.cmvstv.com/upload/system/20190809/201908090542086957.jpg',
      company: '湖南省南子影视传媒有限公司',
      description: '《七彩飘香》的定位是“舌尖上滇峰体验，七彩大地鲜香无限”，宗旨是要让您在风景相伴，趣味相随的过程中体验来自云南高原沃土的生态美景和特色美食。',
      playUrl: 'http://www.iqiyi.com/a_19rrh9782l.html?vfm=2008_aldbd',
      time: '2024-01-01'
    },
    {
      id: 44,
      name: '追光蔚来',
      cover: 'https://sadmin.cmvstv.com/upload/system/20220915/202209151039558858.jpg',
      company: '芒果动听',
      description: '追光蔚来',
      playUrl: 'https://s19.tingdao.com/publicize%2F%E8%8A%92%E6%9E%9C%E5%8A%A8%E5%90%AC%20%E8%BF%BD%E5%85%89%E8%94%9A%E6%9D%A5.mp4',
      time: '2024-01-01'
    },
    {
      id: 45,
      name: '火星食堂乐享非凡，Vivo Xplay6',
      cover: 'https://sadmin.cmvstv.com/upload/system/20190618/201906180631099394.jpg',
      company: '草莓V视',
      description: '火星食堂乐享非凡，Vivo Xplay6',
      playUrl: 'https://cloud.video.taobao.com/play/u/2816746226/p/2/e/6/t/1/50256718903.mp4',
      time: '2024-01-01'
    },
    {
      id: 46,
      name: '安吉星，远程开启空调',
      cover: 'https://sadmin.cmvstv.com/upload/system/20190618/201906180631547816.jpg',
      company: '草莓V视',
      description: '安吉星，远程开启空调',
      playUrl: 'https://cloud.video.taobao.com/play/u/2816746226/p/2/e/6/t/1/50256766239.mp4',
      time: '2024-01-01'
    },
    {
      id: 47,
      name: '千金净雅《万能杂货铺》',
      cover: 'https://sadmin.cmvstv.com/upload/system/20190618/201906180633102941.jpg',
      company: '草莓V视',
      description: '千金净雅《万能杂货铺》',
      playUrl: 'https://cloud.video.taobao.com/play/u/2816746226/p/2/e/6/t/1/50215616285.mp4',
      time: '2024-01-01'
    },
    {
      id: 48,
      name: '减脂训练营',
      cover: 'https://sadmin.cmvstv.com/upload/system/20190618/201906180633502714.jpg',
      company: '草莓V视',
      description: '减脂训练营',
      playUrl: 'https://cloud.video.taobao.com/play/u/2816746226/p/2/e/6/t/1/225648652582.mp4',
      time: '2024-01-01'
    },
    {
      id: 49,
      name: '《旅食说》-白沙古镇',
      cover: 'https://sadmin.cmvstv.com/upload/system/20190618/201906180634345245.jpg',
      company: '草莓V视',
      description: '《旅食说》-白沙古镇',
      playUrl: 'https://cloud.video.taobao.com/play/u/2816746226/p/2/e/6/t/1/225867647733.mp4',
      time: '2024-01-01'
    },
    {
      id: 50,
      name: '美味烘焙小屋',
      cover: 'https://sadmin.cmvstv.com/upload/system/20190618/201906180635143608.jpg',
      company: '草莓V视',
      description: '美味烘焙小屋',
      playUrl: 'https://cloud.video.taobao.com/play/u/2816746226/p/2/e/6/t/1/225867815917.mp4',
      time: '2024-01-01'
    },
  ]);

  const columns = [
    {
      title: '序号',
      dataIndex: 'index',
      key: 'index',
      width: 60,
      align: 'center',
      render: (text, record, index) => index + 1
    },
    {
      title: '封面图',
      dataIndex: 'cover',
      key: 'cover',
      render: (text) => <div style={{ width: '100px', height: '100px' }}><img src={text} alt="" style={{ width: '100%', height: '100%', objectFit: 'cover' }} /></div>
    },
    {
      title: '作品名称',
      dataIndex: 'name',
      key: 'name'
    },
    {
      title: '创作企业',
      dataIndex: 'company',
      key: 'company'
    },
    {
      title: '作品描述',
      dataIndex: 'description',
      key: 'description',
      width: 800,
    },
    {
      title: '作品链接',
      dataIndex: 'playUrl',
      key: 'playUrl',
      render: (text) => <a href={text} target="_blank">查看</a>
    },
    {
      title: '发布时间',
      dataIndex: 'time',
      key: 'time'
    },
  ]
  // 添加活动事件
  const addClick = () => {
    setVisible(true);
    form.resetFields();
  }
  // 确认添加事件
  const handleOk = () => {
    form.validateFields().then(values => {
      let data = {
        id: tableData.length + 1,
        ...values,
        time: dayjs(values.time).format('YYYY-MM-DD')
      }
      setTableData([data, ...tableData]);
      message.open({
        type: 'success',
        content: '添加成功'
      })
      setVisible(false);
    }).catch(err => {
      message.open({
        type: 'error',
        content: '请检查表单填写是否正确'
      })
    });
  }
  // 取消添加事件
  const handleCancel = () => {
    setVisible(false);
    form.resetFields();
  }

  const disabledDate = (current) => {
    // Can not select days before today and today
    return current && current < dayjs().endOf('day');
  };
  return (
    <>
     <div>
        <Card
          title="优秀作品"
          extra={
            <Button type='primary' className='addBtn' onClick={addClick}>添加作品</Button>
          }
        >
          <Table
            className='news-table'
            rowKey={(record) => record.id}
            columns={columns}
            dataSource={tableData}
          />
        </Card>

        <Modal
          title="添加作品"
          open={visible}
          destroyOnClose={true}
          onCancel={handleCancel}
          footer={
            [
              <Button key="back" onClick={handleCancel} className='modalFooterBtn'>取消</Button>,
              <Button key="submit" type="primary" onClick={handleOk} className='modalFooterBtn'>确认</Button>
            ]
          }
        >
          <Form
            form={form}
            labelCol={{ span: 4 }}
            wrapperCol={{ span: 18 }}
            preserve={false}
          >
            <Form.Item
              label="作品名称"
              name="name"
              rules={[{ required: true, message: '请输入作品名称' }]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="创作企业"
              name="company"
              rules={[{ required: true, message: '请选择创作企业' }]}
            >
              <Select placeholder="请选择企业" options={[
                { label: '企业1', value: '1' },
                { label: '企业2', value: '2' },
                { label: '企业3', value: '3' },
                { label: '企业4', value: '4' },
                { label: '企业5', value: '5' },
              ]}></Select>
            </Form.Item>
            <Form.Item
              label="作品描述"
              name="description"
              rules={[{ required: true, message: '请输入作品描述' }]}
            >
              <Input.TextArea />
            </Form.Item>
            <Form.Item
              label="作品链接"
              name="playUrl"
              rules={[{ required: true, message: '请输入作品链接' }]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="发布时间"
              name="time"
              rules={[{ required: true, message: '请选择发布时间' }]}
            >
              <DatePicker
                format="YYYY-MM-DD"
                disabledDate={disabledDate}
              />
            </Form.Item>
          </Form>
        </Modal>
      </div>
    </>
  )
};

export default Works;