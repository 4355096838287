import * as echarts from 'echarts';
import { useEffect, useRef } from 'react';

import './line-chart.scss';

function LineCharts(props) {

  const { chartData } = props;

  const chartRef = useRef(null);

  useEffect(() => {
    const chartInstance = echarts.init(chartRef.current);
    initChart(chartInstance);

    const resizeObserver = new ResizeObserver(() => {
      chartInstance.resize();
    });
    resizeObserver.observe(chartRef.current);

    // 清理函数，用于在组件卸载时移除监听器  
    return () => {
      chartInstance.dispose(); // 销毁ECharts实例  
    };
  }, [])

  const initChart = (chartInstance) => {
    chartInstance.setOption(option);
  }
  const option = {
    color: ['#348E6B', '#91cc75', '#fac858', '#ee6666', '#73c0de', '#3ba272', '#fc8452', '#9a60b4', '#ea7ccc'],
    grid: {
      left: 0,
      bottom: 0,
      top: 0,
      right: 0
    },
    xAxis: [
      {
        show: false,
        type: 'category',
        boundaryGap: false,
        data: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23', '24', '25', '26', '27', '28', '29', '30']
      }
    ],
    yAxis: [
      {
        offset: 0,
        show: false,
        type: 'value',
        max: 'dataMax',
      }
    ],
    series: [
      {
        type: 'line',
        smooth: true,
        lineStyle: {
          width: 0
        },
        showSymbol: false,
        areaStyle: {
          color: '#804EFD'
        },
        data: chartData
      },
    ]
  }

  return (
    <>
      <div className='chart-wrap'>
        <div ref={chartRef} className='chart-line'></div>
      </div>
    </>
  )
};

export default LineCharts;