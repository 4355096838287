import * as echarts from 'echarts';
import { useEffect, useRef } from 'react';
import './line-chart.scss';

function LineChart2(props) {
  const { xdata, ydata } = props;
  const chartRef = useRef(null);
  useEffect(() => {
    const chartInstance = echarts.init(chartRef.current);
    initChart(chartInstance);

    const resizeObserver = new ResizeObserver(() => {
      chartInstance.resize();
    });
    resizeObserver.observe(chartRef.current);

    // 清理函数，用于在组件卸载时移除监听器  
    return () => {
      chartInstance.dispose(); // 销毁ECharts实例  
    };
  }, [xdata, ydata])
  const initChart = (chartInstance) => {
    chartInstance.setOption(option);
  }
  const option = {
    grid: {
      left: 50,
      bottom: 30,
      top: 30,
      right: 30
    },
    tooltip: {
      trigger: 'axis'
    },
    xAxis: {
      type: 'category',
      boundaryGap: false,
      data: xdata
    },
    yAxis: {
      type: 'value',
      boundaryGap: [0, '30%'],
      
    },
    series: [
      {
        type: 'line',
        smooth: 0.6,
        symbol: 'none',
        lineStyle: {
          color: '#804EFD',
          width: 2
        },
        data: ydata
      }
    ]
  };
  return (
    <>
      <div className='chart-wrap2'>
        <div ref={chartRef} className='chart-line'></div>
      </div>
    </>
  );
}

export default LineChart2;