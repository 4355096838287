import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import LayoutComponent from './components/layout';
import AuthComponent from '@/components/auth-component';

import Home from '@/pages/home';
import Login from '@/pages/login';
import Activity from '@/pages/activity';
import News from '@/pages/news';
import Works from '@/pages/works';
import Company from '@/pages/company';
import Users from '@/pages/users';
import Material from '@/pages/material';
import Profile from '@/pages/profile';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route exact path="/login" element={<Login />} />
        <Route path='/' element={<LayoutComponent />}>
          <Route index element={<AuthComponent>
            <Home />
          </AuthComponent>}></Route>
          <Route path='activity' element={<AuthComponent>
            <Activity />
          </AuthComponent>}></Route>
          <Route path='news' element={<AuthComponent>
            <News />
          </AuthComponent>}></Route>
          <Route path='works' element={<AuthComponent>
            <Works />
          </AuthComponent>}></Route>
          <Route path='company' element={<AuthComponent>
            <Company />
          </AuthComponent>}></Route>
          <Route path='users' element={<AuthComponent>
            <Users />
          </AuthComponent>}></Route>
          <Route path='material' element={<AuthComponent>
            <Material />
          </AuthComponent>}></Route>
          <Route path='profile' element={<AuthComponent>
            <Profile />
          </AuthComponent>}></Route>
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default observer(App);
