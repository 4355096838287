import { useState } from 'react';
import { Table, Card, Button, Modal, Form, Input, DatePicker, message } from 'antd';

import dayjs from 'dayjs';
import './news.scss';
function News() {
  const [visible, setVisible] = useState(false);
  const [form] = Form.useForm();
  const [tableData, setTableData] = useState([
    {
      id: 1,
      name: '威海市文登区西部工业园党组副书记鞠红伟一行莅临园区考察交流',
      description: '2024年5月10日，威海市文登区西部工业园党组副书记鞠红伟一行莅临马栏山众创园考察交流。考察组一行参观了园区“星”数据交汇中心、e星实践创新基地等区域，并对目前基地运营工作进行了详细了解及深入沟通交流。',
      time: '2024-05-10'
    },
    {
      id: 2,
      name: '广州市越秀区人民政府区长罗光华一行莅临马栏山众创园考察调研',
      description: '2024年4月2日至3日，广州市越秀区人民政府区长罗光华一行莅临马栏山视频文创产业园，考察调研马栏山视频文创产业园文化产业生态及产业技术。',
      time: '2024-04-03'
    },
    {
      id: 3,
      name: '马栏山众创园党委组织党员集中观看电影《堡垒》',
      description: '4月3日，马栏山众创园党委举办红色观影《堡垒》主题党日活动，马栏山众创园党委组织党员（含预备党员）、发展对象和入党积极分子参加。',
      time: '2024-04-03'
    },
    {
      id: 4,
      name: '人社部就业促进司一行到开福区视频文创零工市场开展调研',
      description: '2月19日下午，人社部就业促进司就业服务处处长、二级巡视员杨颖琳率队来到马栏山视频文创产业园，深入开福区视频文创零工市场、调研稳用工促就业工作。湖南省人社厅就业促进与失业保险处处长吴欣，长沙市人力资源和社会保障局党组书记、局长胡春山，开福区委常委、常务副区长蒋君，开福区委组织部副部长（兼）、区人社局党组书记、局长杨柳艳，马栏山（长沙）视频文创园党工委委员、管委会副主任刘飞等参加调研。',
      time: '2024-02-19'
    },
    {
      id: 5,
      name: '两会速递 | 省政协委员、草莓V视创始人余小海：创新湘粤合作模式，助推湖南省经济高质量发展',
      description: '2023年是全面贯彻中共二十大精神、奋力谱写中国式现代化湖南新篇章的开局之年，也是十三届省政协开局之年。省政协深入学习贯彻习近平总书记关于做好新时代党的统一战线工作的重要思想、习近平总书记关于加强和改进人民政协工作的重要思想，在全国政协有力指导和中共湖南省委坚强领导下，紧紧聚焦全省中心任务，以“全面学、扎实干、作风硬、政协兴”谋篇布局，提出并落实省政协“1453”履职总要求，团结带领全省各级政协组织和广大政协委员，守正创新、开拓进取，在现代化新湖南建设中彰显了政协担当、贡献了政协力量。',
      time: '2024-01-30'
    },
    {
      id: 6,
      name: '浙江省衢州市委统战部副部长郑航一行考察调研马栏山新阶层人士统战工作',
      description: '2024年1月25日上午，浙江省衢州市委统战部副部长郑航、长沙市委统战部副部长杨光华一行莅临马栏山视频文创产业园考察调研马栏山新的社会阶层人士统战工作。',
      time: '2024-01-25'
    },
    {
      id: 7,
      name: '浙江省衢州市人民政府副市长杨圆华一行考察调研马栏山众创园',
      description: '2024年1月25日下午，浙江省衢州市人民政府副市长杨圆华一行莅临马栏山众创园，考察调研园区工作。草莓V视长沙基地负责人彭聃向考察组一行详细介绍了园区运营服务、人才就业、招商政策等情况；随后，双方对园区视频文创产业发展，如何积极服务企业推动高质量发展等领域进行了交流。',
      time: '2024-01-25'
    },
    {
      id: 8,
      name: '湖南信息学院播音与主持艺术专业2023级学生专业见习活动',
      description: '2024年1月9日，湖南信息学院播音与主持艺术专业2023级学生来园区参观学习。通过本次专业见习活动，帮助新生认识传媒行业，了解播音与主持艺术专业在传媒行业中的就业情况。',
      time: '2024-01-09'
    },
    {
      id: 9,
      name: '浙江省电子商务大数据培训班来园区参观交流',
      description: '2024年1月11日，浙江省电子商务大数据培训班来园区参观交流。培训班一行先后参观了园区博胜集团、沐泽珠宝、乐鱼传媒、泰阳电器等企业。',
      time: '2024-01-11'
    },
    {
      id: 10,
      name: '“统战青年说”2023年第5场在园区举办',
      description: '7月31日下午，由长沙市委统战部主办的“统战青年说”2023年第5场如期在马栏山众创园举行，6位青年围绕“做好网络统战工作 走好网络群众路线”提出了见解和思考。',
      time: '2023-07-31'
    },
    {
      id: 11,
      name: '相约花果山  逐梦“华语行”',
      description: '7月29日下午，花果山XR高清演播厅正式开播。100名孩子们在家人和指导老师的陪同下，参加首届“华语行”语言艺术（朗诵）专业成果展演活动，打卡花果山XR高清演播厅。',
      time: '2023-07-29'
    },
    {
      id: 12,
      name: '岳阳市委统战部莅临园区考察调研',
      description: '2023年7月21日，岳阳市委统战部二级巡视员周泽柱一行来到草莓V视调研考察，参观马栏山众创园“e星实践创新基地”工作，先后参观了园区“星”数据交汇中心、党建室等功能区域。参观完毕后，就网络统战工作如何进一步开展，岳阳市委统战部、长沙市委统战部新阶处、开福区委统战部进行了座谈交流。',
      time: '2023-07-21'
    },
    {
      id: 13,
      name: '杭州市委统战部莅临园区考察调研',
      description: '2023年7月25日，杭州市委统战部二级巡视员胡德斌一行莅临马栏山众创园考察调研。胡德斌强调，大家一要认真学习，保持硬实力，在交流中互相学习，在研讨中共同进步，为更好发挥新的社会阶层人士优势作用保持过硬实力；二要主动担当，传播正能量，加强社会责任感与自身使命感，积极传播社会正能量，为营造风清气正的社会氛围贡献新阶层同心力量；三要坚持创新，谋划新发展，新的社会阶层人士有新想法、新观念，要心往一处想、劲往一处使，创新探索发展新路径。',
      time: '2023-07-25'
    },
    {
      id: 14,
      name: '湘江新区统战工作部(群团工作部)、天心区委统战部莅临园区考察调研',
      description: '为更好推动湘江新区、天心区网络统战工作开展，2023年7月26日，湘江新区统战工作部(群团工作部)统战人士工作处处长曾琼柳，天心区委统战部副部长、侨联主席汤沛一行莅临马栏山众创园考察学习及交流。',
      time: '2023-07-26'
    },
    {
      id: 15,
      name: '开福“未来合伙人”实习实践主题活动见面会在园区举办',
      description: '清华大学、中南大学等名校2697名学子报名，竞聘开福区头部企业带薪实习的“Offer”！19日下午，作为“开福合伙人”倍增计划的配套行动，开福区委人才办和区团委联合发起的开福“未来合伙人”实习实践见面会在马栏山众创园一楼举行。',
      time: '2023-07-20'
    },
    {
      id: 16,
      name: '融合十年 笃行致远——2023中国新媒体大会',
      description: '7月13日上午，2023中国新媒体大会之“马栏山时间”文创活动在马栏山视频文创园创智园1号演播厅举行。中华全国新闻工作者协会党组成员、书记处书记吴兢，国家广播电视总局发展研究中心党委书记、主任祝燕南出席。湖南省广播电视局党组书记、局长贺辉，长沙市委常委、市委宣传部部长陈澎出席并致辞讲话。',
      time: '2023-07-13'
    },
    {
      id: 17,
      name: '新疆吐鲁番市党外代表人士培训班莅临马栏山众创园考察调研',
      description: '7月7日下午，吐鲁番市党外代表人士培训班前往马栏山众创园参观e星实践创新基地。通过现场观摩和交流，学员在看、听、思中增进共识，增进“五个认同”，进一步激发了新的社会阶层人士、优秀人才及代表人士参与社会经济发展的积极性。',
      time: '2023-07-07'
    },
    {
      id: 18,
      name: '超清视界·顶峰相见 | 草莓V视-花果山视频文创基地开园啦',
      description: '当众人点亮象征“开园”的智能灯球，前方高清屏幕的XR虚拟场景演示，让人宛如置身在华彩交织的未来世界。2023世界超高清视频产业发展大会开幕之际，作为大会专题会议之一，“超清视界，顶峰相见”——花果山超高清小镇草莓V视园区开园活动暨越秀超高清视频产业高层次人才圆桌会于5月8日在花果山超高清视频产业小镇草莓V视园区举办。',
      time: '2023-05-09'
    },
    {
      id: 19,
      name: '省政协新闻出版界别委员组织举办主题沙龙',
      description: '为学习贯彻党的二十大精神，以实际行动推动中华优秀传统文化走出去，突出界别特色，发挥界别优势，打造履职品牌。4月25日下午，湖南省政协新闻出版界别委员工作室举办2023年中华优秀传统文化走出去主题沙龙。会上，15名界别代表和界别委员分别就非物质文化遗产活态传承、湖湘传统文化创新性发展等方面建言献策。',
      time: '2023-04-25'
    },
    {
      id: 20,
      name: '无锡锡山区委统战部来访马栏山众创园',
      description: '4月26日，无锡市锡山区委常委、统战部部长张爱军一行29人，参观了马栏山众创园“e”星实践创新基地建设等有关工作。',
      time: '2023-04-26'
    },
    {
      id: 21,
      name: '广州市越秀区长罗光华调研考察草莓视花果山视频文创基地',
      description: '4月19日，广州市越秀区长罗光华到草莓v视花果山视频文创基地进行考察调研据悉，越秀区将推动总部经济、楼宇经济规模质量迈上新台阶，力争早日建成创新金融、生命健康、数字经济三个千亿级产业集群和文化创意、现代商贸、高端专业服务三个五百亿级产业集群，不断塑造发展新动能新优势。',
      time: '2023-04-19'
    },
    {
      id: 22,
      name: '团结奋斗新征程，凝心聚力谱新篇 2023年“寻美·中国”主题活动启动大会',
      description: '为深入学习贯彻习近平新时代中国特色社会主义思想，贯彻落实党的二十大精神和中央统战工作会议精神，走好网络群众路线、构建网上网下同心圆，团结引导广大新的社会阶层人士和网络人士为维护意识形态安全发挥正能量，4月7日上午，首届新的社会阶层人士服务团网络人士大会暨“凝聚新力量 筑梦新时代”——2023年“寻美·中国”主题活动启动大会在天津召开。',
      time: '2023-04-07'
    },
    {
      id: 23,
      name: '把握新机遇，构建新生态 | 草莓V视花果山视频文创基地首批入驻企业正式签约',
      description: '把握新机遇，构建新生态。4月3日上午，草莓V视花果山视频文创基地首批入驻企业正式签约。广东文商旅投资集团、琢数港数字科技、一网购等多家企业完成现场签约。',
      time: '2023-04-03'
    },
    {
      id: 24,
      name: '广州市越秀区委书记郭昊羽调研考察草莓视花果山视频文创基地',
      description: '4月3日下午，广州市越秀区委书记郭昊羽到草莓v视花果山视频文创基地进行考察调研。郭昊羽表示，越秀区将持续提升科技创新和产业创新策源能力，构建完善全链条企业服务机制，着力以创新驱动高质量发展，不断塑造发展新动能新优势。',
      time: '2023-04-03'
    },
    {
      id: 25,
      name: '两会速递 | 省政协委员、草莓V视创始人余小海：建议加快打造高质量文创产业人才“蓄水池”',
      description: '1月13日下午，中国人民政治协商会议湖南省第十三届委员会第一次会议在省人民会堂开幕。来自各族各界、各条战线的新一届省政协委员齐聚长沙，积极建言资政，广泛凝聚共识，为奋力谱写中国式现代化的湖南新篇章而团结奋斗。',
      time: '2023-01-17'
    },
    {
      id: 26,
      name: '让民营企业心里更踏实 信心更坚定舞台更广阔',
      description: '“力争民营经济占地区生产总值比重超过70%。”2023年湖南省政府工作报告指出，要让广大民营企业有“优”的服务、“进”的空间、“创”的动力，让广大民营企业心里更踏实、信心更坚定、舞台更广阔。这是深入贯彻党的二十大精神、切实落实“两个毫不动摇”、促进我省民营经济进一步发展壮大的科学安排。',
      time: '2023-01-17'
    },
    {
      id: 27,
      name: '美图公司调研马栏山视频内容企业交流会',
      description: '11月18日下午，美图公司创始人兼CEO吴欣鸿带领公司高管一行赴马栏山众创园开展视频内容企业交流会。',
      time: '2022-11-18'
    },
    {
      id: 28,
      name: '长沙维护新就业形态劳动者劳动保障权益专项执法行动',
      description: '为全面贯彻落实党的二十大报告中提出的关于完善劳动者权益保障制度，加强灵活就业和新就业形态劳动者权益保障的精神，10月19日下午，长沙市人社局党组书记、局长胡春山带领市劳动保障监察支队、市人社局劳动关系处、开福区人社局相关工作人员，前往马栏山众创园督导长沙市新就业形态劳动者权益保障工作。',
      time: '2022-10-19'
    },
    {
      id: 29,
      name: '湖南省人社厅劳动保障监察局赴马栏山众创园开展新就业形态劳动者权益保障调研',
      description: '为深入学习宣传贯彻党的二十大精神，积极构建和谐劳动关系，湖南省人力资源和社会保障厅相关处室单位联合开展“宣贯二十大 和谐三湘行”进企业进园区进街道进社区活动。11月4日，湖南省人社厅劳动保障监察局局长张兴彪率调研团赴马栏山众创园开展新就业形态劳动者权益保障调研。',
      time: '2022-11-04'
    },
    {
      id: 30,
      name: '深入推进“放管服”改革 持续优化营商环境',
      description: '11月9日上午，长沙市行政审批服务局党组书记、局长杨应龙带队来马栏山视频文创园调研，指导园区“放管服”改革和政务服务工作。长沙市行政审批服务局党组成员、副局长陈波等领导参加。园区党工委书记邹犇淼，党工委副书记、管委会主任易鹰以及园区行政审批服务局相关人员陪同。',
      time: '2022-11-09'
    },
    {
      id: 31,
      name: '开福区人大常委会主任会议成员调研新就业形态 保障职工合法权益',
      description: '为维护新就业形态劳动者劳动保障合法权益，推动构建和谐劳动关系，11月10日，开福区人大常委会主任会议成员欧干军带队调研马栏山众创园。',
      time: '2022-11-10'
    },
    {
      id: 32,
      name: '草莓V视 广州花果山视频文创基地进入全面建设阶段',
      description: '秋高气爽日，园区建设忙。告别了酷热的夏日，草莓V视-广州花果山视频文创基地建设进入了一个新的阶段。',
      time: '2022-09-24'
    },
    {
      id: 33,
      name: '生命至上 消防为先 马栏山众创园开展消防安全培训',
      description: '为进一步提升员工的消防安全意识和逃生自救能力，普及消防安全知识，推进园区日常消防安全管理工作，9月20日，马栏山众创园举行主题为“生命至上 消防为先”消防安全培训，各企业代表参加了本次培训。',
      time: '2022-09-20'
    },
    {
      id: 34,
      name: '马栏山“四不两直”暗访检查园区安全生产确保消防安全措施落实到位',
      description: '为落实全省安全防范动员电视电话会议有关要求，9月21日下午，园区党工委副书记、管委会主任易鹰带队以“四不两直”的方式对园区重点企业进行安全生产和消防安全的暗访检查。',
      time: '2022-09-20'
    },
    {
      id: 35,
      name: '劳动关系 和谐同行——企业用工风险防控',
      description: '9月23日下午，以“劳动关系 和谐同行——企业用工风险防控”为主题的“马栏山大讲堂”第44期在马栏山众创园一楼报告厅开讲。马栏山（长沙）视频文创园党工委委员、管委会副主任胡朝晖，长沙市人力资源和社会保障局四级调研员刘星亮、市劳动人事争议仲裁院副院长何志敏出席活动。园区管委会机关部分工作人员及园区50多家企业单位人事负责人参加。',
      time: '2022-09-23'
    },
    {
      id: 36,
      name: '湖南省委常委、省委统战部部长隋忠诚调研草莓V视',
      description: '2022年9月6日，湖南省委常委、统战部部长隋忠诚来到草莓V视调研考察，指导马栏山众创园“e星实践创新基地”工作。',
      time: '2022-09-06'
    },
    {
      id: 37,
      name: '草莓V视走进多彩贵州 | 统一战线赋能地方经济社会发展新实践',
      description: '为深入贯彻落实中央统战工作会议精神，充分发挥网络人士 “数字势能”优势，助推地方经济社会发展，中央统战部于8月15日至19日在贵州举办了“喜迎二十大·E路心向党”网络人士主题活动。',
      time: '2022-08-15'
    },
    {
      id: 38,
      name: '“魅力潇湘·巨变乡村”——开福区统一战线助力城步县乡村振兴“多点开花”',
      description: '7月13日至15日，长沙市开福区统一战线赴邵阳市城步苗族自治县开展“魅力潇湘·巨变乡村”主题调研活动，发挥统战优势助力城步县乡村振兴。活动由开福区委统战部主办、中国网络文学小镇承办。开福区委统战部、区民宗局、区工商联、区侨联、各民主党派区工委、区党外知联会、区对口帮扶城步工作组、区新阶联、区工商联青商会，湖南省网络作家协会、中国网络文学小镇等单位共50余人参加此次活动。开福区委副书记、统战部长、区委党校校长晏成方，区人大常委会副主任王硕出席活动。长沙市委统战部新阶处处长蒋耀明应邀指导。城步县委副书记、统战部长、县委党校校长曾艺，县政协副主席（兼）、侨联主席粟春娟，县政协副主席（兼）、工商联主席唐圣成及相关部门陪同。',
      time: '2022-07-13'
    },
    {
      id: 39,
      name: '向世界讲好中国故事精彩长沙篇章',
      description: '7月12日，长沙市2022年度外宣工作协作交流会召开。市委常委、市委宣传部部长陈澎出席。',
      time: '2022-07-12'
    },
    {
      id: 40,
      name: '《数&智招聘体系搭建》线下招聘沙龙分享会',
      description: '不同发展阶段企业人力如何支撑企业发展、告诉发展期企业招聘实战案例、人人都是招聘官最佳实践……7月14日，一场干货满满、能量爆棚的《数&智招聘体系搭建》线下招聘沙龙分享会在马栏山众创园举行，以“数智”和人才赋能，为“强省会”增添新动能。',
      time: '2022-07-14'
    },
    {
      id: 41,
      name: '看见马栏山 共画同心圆“毛泽东主席成功之路对创业者的启示”讲座活动顺利举行',
      description: '为迎接党的二十大胜利召开，在党的统一战线政策提出100周年、开启统一战线事业第二个百年的重要一年之际，马栏山网络人士联谊会坚持大团结、大联合，为凝聚起新时代网络界人士与党同心同行、团结奋进的磅礴力量，组织开展“看见马栏山”系列活动。7月21日下午，系列活动之“毛泽东主席成功之路对创业者的启示”讲座，在中国网络文学小镇举行。主讲嘉宾、市委统战部副部长、市侨办主任饶福明、开福区委统战部副部长莫琳、马栏山视频文创园产业局副局长韩哲等领导出席活动。常德市创业协会会长赵晋生、景想传媒董事长欧凯作为特邀嘉宾列席活动。',
      time: '2022-07-21'
    },
    {
      id: 42,
      name: '马鞍山市文旅局党委书记局长黄舜调研马栏山众创园',
      description: '为探索文旅融合发展新路径，7月21日，马鞍山市文旅局党委书记局长黄舜率调研团一行前往马栏山众创园考察调研。',
      time: '2022-07-21'
    },
    {
      id: 43,
      name: '开福区委书记罗玉环调研马栏山众创园',
      description: '为进一步发挥园区的典型引领和榜样示范作用，推动园区成为行业和区域经济社会高质量发展的重要引擎，6月29日下午，开福区委书记罗玉环带领区委办、区政府办、区委组织部、公安分局、区财政局、区城管局等区直部门负责人，深入马栏山视频文创园部分重点产业园区和企业进行调研。',
      time: '2022-06-29'
    },
    {
      id: 44,
      name: '征兵宣传进园区，点燃青年强军梦',
      description: '“志在军营，不负韶华”。为切实做好2022年度征兵工作，大力营造浓厚的征兵宣传氛围，7月1日，草莓V视参与开福区2022年征兵工作领导小组会。',
      time: '2022-07-01'
    },
    {
      id: 45,
      name: '洪山街道“政企联心·共话发展”晚餐会',
      description: '为打造优质营商环境，增强辖区经济发展动力，推动经济提质扩量增效，6月30日晚，“政企联心·共话发展”晚餐会在马栏山众创园“开餐”，辖区十多家重点企业参与“点单”，由洪山街道协同草莓V视现场“接单”。开福区政协党组书记、主席蔡冰，马栏山视频文创园管委会副主任蹇桂军，洪山街道党工委书记尹显亮等领导参加。',
      time: '2022-06-30'
    },
    {
      id: 46,
      name: '“送政策、解难题、优服务”政银企座谈会',
      description: '为推动“送政策、解难题、优服务”活动落地落实，打造高效服务平台，7月1日上午，“送政策、解难题、优服务”政银企座谈会在马栏山众创园1楼多功能厅举办。',
      time: '2022-07-01'
    },
    {
      id: 47,
      name: '寻美湖南 凝聚“新”力量',
      description: '6月15日上午，“寻美湖南 相约湖南”—新的社会阶层人士助力湖南旅游发展主题活动启动仪式在张家界举行，采取线上线下相结合的方式，14个市州在线参加，全省同时启动有关“寻美”主题活动。湖南省委统战部副部长廖建华出席启动仪式并讲话，张家界市委常委、统战部部长廖国豪主持。',
      time: '2022-06-15'
    },
    {
      id: 48,
      name: '看见马栏山·乐见未来 乐言科技电商新增长沙龙',
      description: '随着直播电商的崛起，如何用更好的内容形态突破流量和吸引客户，创造出自己的电商形象，是电商企业值得探索的方向。6月10日，草莓V视联合乐言科技于马栏山众创园开展了“看见马栏山·乐见未来——乐言科技电商新增长沙龙”，助力企业通过直播电商实现新增长。',
      time: '2022-06-10'
    },
    {
      id: 49,
      name: '马鞍山市委常委、宣传部长陈永红一行赴草莓V视调研',
      description: '6月11日，马鞍山市委常委、宣传部长陈永红率调研团一行来访草莓V视。',
      time: '2022-06-11'
    },
    {
      id: 50,
      name: '开福区、岳麓区开展“互学互访”主题活动',
      description: '为进一步推进“强省会 兴文化”国际文化创意中心建设，增进与兄弟区县之间的了解与合作，实现文创企业资源共享与合作共赢，共同推动文创产业进步。6月8日，长沙市开福区与岳麓区开展“走访交流促合作，互动共建谋发展”为主题的文化企业互访交流活动。',
      time: '2022-06-08'
    },
    {
      id: 51,
      name: '向新而行 创新增值——爱奇艺调研马栏山短视频内容公司',
      description: '为进一步推动马栏山众创园入驻企业的交流合作，助力业务合作、资源共享和创新发展的需求，5月27日，草莓V视特邀爱奇艺副总裁杨海涛、爱奇艺短视频运营负责人、随刻事业部高级总监张晓曦来访马栏山众创园，并于多功能厅开展“向新而行 创新增值——爱奇艺调研马栏山短视频内容公司”主题活动，二咖传媒、博胜集团、多豆乐漫娱等16家企业参与。',
      time: '2022-05-27'
    },
    {
      id: 52,
      name: '寻美长沙 • 红心向党 长沙新阶层人士代表共书时代共唱未来',
      description: '5月23日，在马栏山众创园内，伴随着阵阵慷慨激昂的朗诵声，寻美长沙•红心向党主题活动拉开序幕，五十余位长沙市新的社会阶层人士“百人会”成员代表参与。',
      time: '2022-05-23'
    },
    {
      id: 53,
      name: '雨花区委统战部赴马栏山众创园考察学习',
      description: '为加强基层统战工作的交流合作，学习借鉴开福区统一战线工作实践创新项目先进经验，5月27日，雨花区委统战部常务副部长谢锋带队，组织全区统一战线单位50人来到马栏山众创园“e”星实践创新基地，对“星”数据交汇中心、新阶层学习社、“星”网红直播间进行详细学习了解。',
      time: '2022-05-27'
    },
    {
      id: 54,
      name: '凝聚新力量·筑梦新时代——2022 年“寻美·中国”再出发！',
      description: '5月13日上午，由中共中央统战部新的社会阶层人士统战工作局、中共天津市委统战部、中共天津市委网信办指导，全国新的社会阶层人士服务团网络人士分团、天津市新的社会阶层人士联谊会主办，全国各新的社会阶层人士联合会参与的2021年“寻美·中国”主题活动总结活动暨2022年“寻美·中国”再出发启动仪式顺利开展。',
      time: '2022-05-13'
    },
    {
      id: 55,
      name: '',
      description: '',
      time: ''
    },
  ]);

  const columns = [
    {
      title: '序号',
      dataIndex: 'index',
      key: 'index',
      width: 60,
      align: 'center',
      render: (text, record, index) => index + 1
    },
    {
      title: '标题',
      dataIndex: 'name',
      key: 'name'
    },
    {
      title: '简介',
      dataIndex: 'description',
      key: 'description',
      width: 800
    },
    {
      title: '发布时间',
      dataIndex: 'time',
      key: 'time'
    },
  ]
  // 添加活动事件
  const addClick = () => {
    setVisible(true);
    form.resetFields();
  }
  // 确认添加事件
  const handleOk = () => {
    form.validateFields().then(values => {
      let data = {
        id: tableData.length + 1,
        ...values,
        time: dayjs(values.time).format('YYYY-MM-DD')
      }
      setTableData([data, ...tableData]);
      message.open({
        type: 'success',
        content: '添加成功'
      })
      setVisible(false);
    }).catch(err => {
      message.open({
        type: 'error',
        content: '请检查表单填写是否正确'
      })
    });
  }
  // 取消添加事件
  const handleCancel = () => {
    setVisible(false);
    form.resetFields();
  }

  const disabledDate = (current) => {
    // Can not select days before today and today
    return current && current < dayjs().endOf('day');
  };
  return (
    <>
      <div>
        <Card
          title="企业动态"
          extra={
            <Button type='primary' className='addBtn' onClick={addClick}>添加新闻</Button>
          }
        >
          <Table
            className='news-table'
            rowKey={(record) => record.id}
            columns={columns}
            dataSource={tableData}
          />
        </Card>

        <Modal
          title="添加动态"
          open={visible}
          destroyOnClose={true}
          onCancel={handleCancel}
          footer={
            [
              <Button key="back" onClick={handleCancel} className='modalFooterBtn'>取消</Button>,
              <Button key="submit" type="primary" onClick={handleOk} className='modalFooterBtn'>确认</Button>
            ]
          }
        >
          <Form
            form={form}
            labelCol={{ span: 4 }}
            wrapperCol={{ span: 18 }}
            preserve={false}
          >
            <Form.Item
              label="标题"
              name="name"
              rules={[{ required: true, message: '请输入标题' }]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="简介"
              name="description"
              rules={[{ required: true, message: '请输入简介' }]}
            >
              <Input.TextArea />
            </Form.Item>
            <Form.Item
              label="发布时间"
              name="time"
              rules={[{ required: true, message: '请选择发布时间' }]}
            >
              <DatePicker
                format="YYYY-MM-DD"
                disabledDate={disabledDate}
              />
            </Form.Item>
          </Form>
        </Modal>
      </div>
    </>
  )
};

export default News;